import * as React from 'react';
import styled from 'styled-components/macro';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// import BillingInformation from './BillingInformation';
import BillingInformation from './newCheckout/BillingInformation';

const BillingContainer = styled.div`
    /* width: 500px; */
    margin: 0 auto;

    @media (max-width: 600px) {
        width: unset;
    }
`;

interface BillingProps {
    stripeKey: string;
}

const Billing: React.FC<BillingProps> = (props) => {
    if (props.stripeKey.length === 0) {
        return <BillingContainer>Talking to Stripe...</BillingContainer>;
    }

    const stripePromise = loadStripe(props.stripeKey);

    return (
        <BillingContainer>
            <Elements stripe={stripePromise}>
                <BillingInformation />
            </Elements>
        </BillingContainer>
    );
};

export default Billing;
