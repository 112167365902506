import { ActionType, Action } from './types';

export const clearCart = (): Action => {
    return {
        type: ActionType.CLEAR_CART
    }
}

export const putCartFromApi = ({ item }): Action => {
    return {
        item,
        type: ActionType.PUT_CART_FROM_SERVER,
    }
}

export const clearCartFromServer = (): Action => {
    return {
        type: ActionType.CLEAR_CART_FROM_SERVER
    }
}

export const togglePayInterval = ({ item }): Action => {
    return {
        item,
        type: ActionType.TOGGLE_SUBSCRIPTION_PAY_INTERVAL,
    }
}

export const addSubscriptionToCart = ({ item }): Action => {
    return {
        item,
        type: ActionType.ADD_SUBSCRIPTION_TO_CART,
    }
}

export const convertSubscriptionToTeam = ({ item }): Action => {
    return {
        item,
        type: ActionType.CONVERT_SUBSCRIPTION_TO_TEAM,
    }
}

export const removeSubscription = ({ item }): Action => {
    return {
        item,
        type: ActionType.REMOVE_SUBSCRIPTION,
    }
}

export const addLicenseToCart = ({ item }): Action => {
    return {
        item,
        type: ActionType.ADD_LICENSE_TO_CART,
    }
}

export const convertLicenseToTeam = ({ item }): Action => {
    return {
        item,
        type: ActionType.CONVERT_LICENSE_TO_TEAM,
    }
}

export const removeLicense = ({ item }): Action => {
    return {
        item,
        type: ActionType.REMOVE_LICENSE,
    }
}

export const addLicenseUpgradeToCart = ({ item }): Action => {
    return {
        item,
        type: ActionType.ADD_LICENSE_UPGRADE_TO_CART,
    }
}


export const updateUpgradeQuantity = ({ item }): Action => {
    return {
        item,
        type: ActionType.UPDATE_UPGRADE_QUANTITY_IN_CART,
    }
}

export const removeLicenseUpgrade = ({ item }): Action => {
    return {
        item,
        type: ActionType.REMOVE_LICENSE_UPGRADE,
    }
}

export const updateQuantityDiscount = (): Action => {
    return {
        type: ActionType.QUICK_QUANTITY_DISCOUNT,
    };
};

export const setCart = ({ cart }): Action => {
    return {
        cart,
        type: ActionType.SET_CART,
    };
};
