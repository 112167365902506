import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import styled from 'styled-components/macro';

import OmniHeader from '../components/OmniHeader';
import StoreHeader from '../components/StoreHeader';
import Cart from '../components/Cart';
import MoreInformation from '../components/MoreInformation';
import OmniFocus from './products/OmniFocus';
import OmniGraffle from './products/OmniGraffle';
import OmniPlan from './products/OmniPlan';
import OmniOutliner from './products/OmniOutliner';
import FourOhFour from '../components/FourOh';
import StoreInventory from '../components/StoreInventory';
import CheckoutPage from '../components/CheckoutPage';
import RegisterLicense from '../components/RegisterLicense';
import * as vars from '../styles/variables';

import {
    getManagedTeamLicensesInfo,
    getUserInfo,
    getPurchaseData,
} from '../utils/getUserData';
import getPurchaseConf from '../utils/getPurchaseConf';
import { handleSignOut } from '../utils/handleSignInSignOut';
import tokenRefresh from '../utils/tokenRefresh';

import getSubscriptions from '../utils/getSubscriptions';
import getConfiguration from '../utils/getConfiguration';
import getEligibleUpgrades from '../utils/getUpgradeItems';

import {
    addSubscriptionToCart,
    addLicenseToCart,
    addLicenseUpgradeToCart,
    removeLicenseUpgrade,
    updateQuantityDiscount,
    clearCart,
} from '../actions/cart';
import { setAuthToFalse } from '../actions/accounts';
import { LSKey, localStorageService } from '../utils/handleLocalStorage';


const MainContainer = styled.div`
    flex-grow: 1;
`;

const IndexContainer = styled.div`
    max-width: 1024px;
    margin: 10px auto;
`;

const App: React.FC = () => {
    const dispatch = useDispatch();

    const { trackPageView } = useMatomo();
    const cart = useSelector((state: OmniStore.GlobalReduxState) => ({ cart: state.cart.hideCart, }));
    const cartRef = useRef(false);
    const accountsApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.accountsAPIURL);
    const purchaseApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.purchaseAPIURL);
    const taxserviceURL = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.taxserviceURL);

    const accessToken = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.accessToken);
    const customerID = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.expandedUserInfo);
    const location = useLocation();

    // const purchasedSubscriptions = useSelector((state: OmniStore.GlobalReduxState) => state.subscriptions);
    //     console.log('purchasedSubscriptions', purchasedSubscriptions)
    // ///items.data[0].plan.id
    const [downloadedRev, setDownloadedRev] = useState<string>('');
    const [stripeKey, setStripeKey] = useState<string>('');

    useEffect(() => {
        cartRef.current = cart.cart;
    }, [cartRef, cart]);

    useEffect(() => {
        const revision = async () => {
            return await getConfiguration();
        };

        revision()
            .then((result) => {
                setDownloadedRev(result.revision);
            })
            .catch((err) => setDownloadedRev(err));
    }, []);

    useEffect(() => {
        if (downloadedRev !== '') {
            console.log('Revision: ', downloadedRev);
            console.log('Purchase: ', purchaseApiUrl);
            console.log('Accounts: ', accountsApiUrl);
            console.log('TaxService', taxserviceURL);
        }
    }, [downloadedRev, accountsApiUrl, purchaseApiUrl, taxserviceURL]);

    useEffect(() => {
        if (cartRef.current && location.pathname !== '/cart') {
            dispatch(clearCart());
            getPurchaseData(accessToken, accountsApiUrl)
            getSubscriptions({
                accessToken,
                customerID: customerID.customer_id[0],
                inactive: false,
                purchaseApiUrl,
            })
        }
    }, [cartRef, location.pathname, dispatch]);

    useEffect(() => {
        const parsedCart = localStorageService.get(LSKey.Cart);
        const addLocalStorageSubscriptionsToRedux = (items: Array<OmniStore.SubscriptionCartItem>) => {
            items.map((item) => {
                dispatch(
                    addSubscriptionToCart({
                        item: { ...item },
                    })
                );
                return null;
            });
        };
        const addLocalStorageLicensesToRedux = (items: Array<OmniStore.LicenseCartItem>) => {
            items.map((item) => {
                dispatch(
                    addLicenseToCart({
                        item: { ...item },
                    })
                );
                return null;
            });
        };
        const addLocalStorageUpgradesToRedux = (items: Array<OmniStore.LicenseUpgradeCartItem>) => {
            items.map((item) => {
                dispatch(
                    addLicenseUpgradeToCart({
                        item: { ...item },
                    })
                );
                return null;
            });
        };

        if (parsedCart !== null) {
            if (parsedCart.subscriptions || parsedCart.licenses) {
                addLocalStorageSubscriptionsToRedux(parsedCart.subscriptions);
                addLocalStorageLicensesToRedux(parsedCart.licenses);
            }
            if (parsedCart.upgrades) {
                addLocalStorageUpgradesToRedux(parsedCart.upgrades);
            }
        }
        dispatch(updateQuantityDiscount());
    }, [ dispatch ]);

    useEffect(() => {
        const parsedCart = localStorageService.get(LSKey.Cart);
        const removeUpgradeItems = (items: Array<OmniStore.LicenseUpgradeCartItem>) => {
            items.map((item) => {
                dispatch(
                    removeLicenseUpgrade({
                        item: { ...item },
                    })
                )
            })
        }
        if (accessToken) {
            getEligibleUpgrades({
                accessToken, 
                purchaseApiUrl
            });
        }
        if (!accessToken) {
            if (parsedCart !== null && parsedCart.upgrades) {
                removeUpgradeItems(parsedCart.upgrades);
            }
        }
    }, [accessToken])

    useEffect(() => {
        trackPageView({ href: location.pathname });
    }, [location.pathname]);

    useEffect(() => {
        getPurchaseConf(purchaseApiUrl)
            .then((result) => setStripeKey(result.publishableKey ? result.publishableKey : ''));
    }, []);

    useEffect(() => {
        //As soon as the customer ID is not empty, GET subscriptions
        if (!customerID) return;
        if (!customerID.customer_id) return;
        if (customerID.customer_id.length === 0) return;

        getSubscriptions({
            accessToken,
            customerID: customerID.customer_id[0],
            inactive: false,
            purchaseApiUrl,
        });
    }, [customerID]);

    useEffect(() => {
        if (!accessToken) return;
        getManagedTeamLicensesInfo(accessToken, accountsApiUrl);
    }, [accessToken, customerID]);

    useEffect(() => {
        if (!accessToken) return;
        getPurchaseData(accessToken, accountsApiUrl);
    }, [accessToken, customerID]);

    useEffect(() => {
        if (!accessToken) return;
        getUserInfo(accessToken, accountsApiUrl);
    }, [accessToken]);

    useEffect(() => {
        const parsedAuth = localStorageService.get(LSKey.StoreAuth);
        const currentTime = Math.round(Date.now() / 1000);

        const refreshToken = async () => {
            Promise.all([
                tokenRefresh({accountsApiUrl, refreshToken: parsedAuth.refreshToken}),
                getUserInfo(accessToken, accountsApiUrl),
                getManagedTeamLicensesInfo(accessToken, accountsApiUrl)
            ])
                .catch(() =>  {
                    console.log("There was an issue refreshing the user's `accessToken`")
                    handleSignOut(accessToken, accountsApiUrl)
                });
        };

        if (parsedAuth && parsedAuth.expiresAt - currentTime > 0) {
            console.log("The user's `accessToken` does not need to be refreshed.");
        } else if (parsedAuth && parsedAuth.expiresAt - currentTime < 0) {
            refreshToken()
        } else if (!parsedAuth) {
            dispatch(setAuthToFalse());
        }
    }, [dispatch]);

    const inventoryColor = location.pathname === '/omnifocus/' ? vars.purple
        : location.pathname === "/omnigraffle/" ? vars.green
        : location.pathname === "/omnioutliner/" ? vars.orange
        : location.pathname === '/omniplan/' ? vars.yellow
        : vars.blue;

    return (
        <MainContainer>
            <OmniHeader inventoryColor={inventoryColor}/>
            <IndexContainer>
                <StoreHeader inventoryColor={inventoryColor}/>
                <StoreInventory
                    color={inventoryColor}
                    collapsed={location.pathname === '/' ? true : false}
                    hidden={
                        location.pathname === '/checkout' ||
                        location.pathname === '/signin'
                            ? true
                            : false
                    }
                />
            </IndexContainer>
            <Routes>
                <Route path='/' element={<MoreInformation />} />
                <Route path='four-oh' element={<FourOhFour />} />
                <Route path='omnifocus' element={<OmniFocus />} />
                <Route path='omnigraffle' element={<OmniGraffle />} />
                <Route path='omniplan' element={<OmniPlan />} />
                <Route path='omnioutliner' element={<OmniOutliner />} />
                <Route path='cart' element={<Cart />} />
                <Route
                    path='checkout'
                    element={<CheckoutPage stripeKey={stripeKey} />}
                />
                {/* <Route
                    path='signin'
                    element={<SignInForm forceSignInFormOpen={true} />}
                /> */}
                <Route path='register-license' element={<RegisterLicense />} />
                <Route path='*' element={<FourOhFour />} />
            </Routes>
        </MainContainer>
    );
};

export default App;
