export const checkCartUpgrades = ({cartUpgrades, eligibleUpgrades, returnIneligibleItem}: {
    cartUpgrades: Array<OmniStore.LicenseUpgradeCartItem>, 
    eligibleUpgrades: OmniStore.GlobalEligibleLicenseUpgrades,
    returnIneligibleItem?: boolean
}) => {
    let eligibleCartItems = true;
    let ineligibleProduct;
    const availableProductUpgrades = Object.keys(eligibleUpgrades);

    const availableItemUpgrades = availableProductUpgrades.reduce((accum, item) => {
	    const current_product = eligibleUpgrades[item][0];
        accum[item] = {
            [current_product.registration_identifier]: current_product.upgrade_quantity
        }
        return accum;
    }, {});

    cartUpgrades.forEach((item) => {
        const availUpgradeIds = Object.keys(availableItemUpgrades);


        // if upgrade in cart is does not exist
      	if (!availUpgradeIds.includes(item.upgradeTo)) {
            eligibleCartItems = false;
            ineligibleProduct = item;
            return;
        } 
        const upgrade_item = availableItemUpgrades[item.upgradeTo];
        const upgrade_quantity = upgrade_item[item.registration_identifier];
        if (item.quantity > upgrade_quantity) {
            eligibleCartItems = false;
            ineligibleProduct = item;
            return;
        }
    });
    return returnIneligibleItem ? {item: ineligibleProduct, status: eligibleCartItems} : eligibleCartItems;
}

export const checkCartSubscriptions = ({cartItems, purchasedSubscriptions, returnIneligibleItem}: {
    cartItems: Array<OmniStore.SubscriptionCartItem>,
    purchasedSubscriptions: Array<OmniStore.AccountPurchases>,
    returnIneligibleItem?: boolean;
}) => {
    let eligibleCartItems = true;
    let ineligibleProduct;
    
    cartItems.forEach((item) => {
        purchasedSubscriptions.forEach((sub) => {
            if (sub.vending_product_id[0] === item.blockableVendingIds[0]) {
                eligibleCartItems = false;
                ineligibleProduct = item;
                return;
            }
        })
        if (eligibleCartItems === false) {
            return;
        }
    });
    return returnIneligibleItem ? {item: ineligibleProduct, status: eligibleCartItems} : eligibleCartItems;
}