import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import StoreFrontContainer from '../StoreContainer';

import getEligibleUpgrades from '../../utils/getUpgradeItems';
import { clearCart, clearCartFromServer } from '../../actions/cart';
import { LSKey, localStorageService } from '../../utils/handleLocalStorage';
import * as vars from "../../styles/variables";

const Header = styled.h1`
    text-align: center;
    margin: 30px auto;
    line-height: 1.3em;
    color: ${vars.whiteColor};
`;

const ProductInformationAndHelp = styled.div`
    margin: 20px auto;
    max-width: 700px;
    color: ${vars.whiteColor};

    > p {
        > a {
            color: ${vars.whiteColor};
        }
    }
`;

const ProductList = styled.ul`
    font-size: 20px;

    li {
        list-style-position: inside;
        width: 450px;
        text-align: left;
    }
`;

interface SuccessProps {
    successCart: {
        licenses?: Array<{
            displayName: string; 
            standardOrPro: string
        }>;
        subscriptions?: Array<string>;
        upgrades?: Array<{
            displayName: string; 
            standardOrProUpgrade: string
        }>;
    }
}

const Success: React.FC<SuccessProps> = (props) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const signedInAccount = useSelector((state: OmniStore.GlobalReduxState) => ({
        account: state.accounts.expandedUserInfo,
    }));
    const accessToken = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.accessToken);

    const accountsApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.accountsAPIURL);
    const purchaseApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.purchaseAPIURL);

    const licenses = props.successCart.licenses;
    const upgrades = props.successCart.upgrades;
    const subscriptions = props.successCart.subscriptions;


    //remove localstorage cart after successful purchase
    //get most recent eligible upgrades for purchase
    React.useEffect(() => {
        localStorageService.remove(LSKey.Cart);
        dispatch(clearCart())
        dispatch(clearCartFromServer())
        getEligibleUpgrades({accessToken, purchaseApiUrl});
    }, []);


    const getProductName = (useage: 'upgrade' | 'license', displayName: string, productType: string) => {
        if (productType === 'PRO') {
            if (displayName.includes('Plan')) {
                return useage === 'license' 
                    ? 'OmniPlan 4 Pro for Mac Licenses' 
                    :'OmniPlan 4 Pro for Mac License Upgrade'
                ;
            } 
        }
        if (displayName.includes('OmniOutliner 5')){
            return useage === 'license' 
                ? 'OmniOutliner 5 Essentials for Mac'
                : 'OmniOutliner 5 Essentials for Mac License Upgrade'
            ;
        }
        return useage === 'license' 
            ? displayName
            : `${displayName} License Upgrade`
        ;
    };

    return (
        <StoreFrontContainer>
            <Header>
                <FormattedMessage id='thankYouExclamation' />
                <ProductList>
                    {licenses && licenses.map((license, key) => {
                        const name = getProductName('license', license.displayName, license.standardOrPro);
                        return <li key={key}>{name}</li> 
                    })}
                    {upgrades && upgrades.map((license, key) => {
                        const name = getProductName('upgrade', license.displayName, license.standardOrProUpgrade);
                        return <li key={key}>{name}</li> 
                    })}
                    {subscriptions && subscriptions.map((product, key) => {
                        return <li key={key}>{product}</li>
                    })}
                </ProductList>
            </Header>
            <ProductInformationAndHelp>
                <p>
                    <FormattedMessage
                        id='storePurchaseSuccessPage'
                        values={{ 
                            OmniAccountsLink: (
                                <a href={accountsApiUrl}>
                                    {intl.formatMessage({
                                        id: 'omniAccountsLinkText',
                                    })}
                                </a>
                            ),
                            userEmail: signedInAccount.account?.email }}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id='instructionsOnPurchase'
                        values={{ userEmail: signedInAccount.account?.email }}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id='contactUsPostPurchase'
                        values={{
                            EmailSupport: (
                                <a href='https://support.omnigroup.com'>
                                    {intl.formatMessage({
                                        id: 'contactUsShortString',
                                    })}
                                </a>
                            ),
                        }}
                    />
                </p>
            </ProductInformationAndHelp>
        </StoreFrontContainer>
    )
};

export default Success;