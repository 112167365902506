import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import StoreFrontContainer from './StoreContainer';
import Cart from './Cart';
import Billing from './Billing';
import CartBreadCrumbs from './CartBreadCrumbs';
import { getUserInfo } from '../utils/getUserData';

interface CheckoutProps {
    stripeKey: string;
}

const CheckoutPage: React.FC<CheckoutProps> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const accountsApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.accountsAPIURL);
    const accessToken = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.accessToken);
    const userInfo = useSelector((state: OmniStore.GlobalReduxState) => ({ account: state.accounts.expandedUserInfo}));
    const [account_state, setAccountState] = useState<number | null>(null);

    const subscriptions = useSelector((state: OmniStore.GlobalReduxState) => state.cart.cart.subscriptions);
    const licenses = useSelector((state: OmniStore.GlobalReduxState) => state.cart.cart.licenses);
    const upgrades = useSelector((state: OmniStore.GlobalReduxState) => state.cart.cart.upgrades);
    const hasAuth = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.has_auth);

    useEffect(() => {
        if (!userInfo) {
            getUserInfo(accessToken, accountsApiUrl);
        }
        if (!account_state && userInfo.account) {
            setAccountState(userInfo.account.account_state);
        }
    }, [account_state, userInfo])

    useEffect(() => {
        if ((subscriptions.length === 0 && licenses.length === 0 && upgrades.length === 0) || hasAuth === false) {
            navigate('/cart');
        }
    }, [hasAuth]);

    return (
        <StoreFrontContainer>
            <CartBreadCrumbs page={'PAYMENT'} />
            {location.pathname === '/cart' && (
                <Cart simple />
            )}
            { account_state && (account_state === 300 || account_state == 301) ? (
                <div style={{color: 'white'}}>
                    This Omni Account is closed and cannot be used to make purchases. 
                    Please contact us at sales@omnigroup.com for further assistance.
                </div>
            ) : (
                <Billing stripeKey={props.stripeKey} />
            )}
        </StoreFrontContainer>
    );
};

export default CheckoutPage;
