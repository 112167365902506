import { UPDATE_PAYMENT_INTENT_EP } from '../constants/PurchaseEndpoints';
import { Metadata } from '@stripe/stripe-js';
import { LSKey, localStorageService } from './handleLocalStorage';

interface PostPaymentIntentProps {
    accessToken: string;
    cart: { 
        new_purchases: Array<{ product_id: string; quantity: number }>,
        upgrades: {
            [upgradeID: string]: Array<{
                upgrade_of: string;
                upgrade_quantity: number;
            }>;
        }
    };
    customer_id: string;
    jurisdiction?: string;
    metadata?: Metadata;
    paymentIntentID: string;
    paymentMethodID?: string;
    purchaseApiUrl: string;
    setup_future_usage?: boolean;
    tax_rate?: string;
    updatedAmount: number | null;
}

interface PaymentIntentUpdateResponse {
    cs?: string;
    id?: string;
    error?: string;
}

export const postUpdatePaymentIntent = async ({
    accessToken,
    cart,
    customer_id,
    jurisdiction,
    metadata,
    paymentIntentID,
    paymentMethodID,
    purchaseApiUrl,
    setup_future_usage = true,
    tax_rate,
    updatedAmount,
}: PostPaymentIntentProps): Promise<PaymentIntentUpdateResponse> => {
    try {
        const updatePaymentIntent = await fetch( `${purchaseApiUrl}${UPDATE_PAYMENT_INTENT_EP({
            customer_id: customer_id,
            payment_intent_id: paymentIntentID,
        })}`,
            {
                body: JSON.stringify({
                    amount: updatedAmount ? updatedAmount : null,
                    cart: cart,
                    currency: 'usd',
                    jurisdiction: jurisdiction ? jurisdiction : null,
                    metadata: metadata ? metadata : null,
                    payment_method: paymentMethodID,
                    setup_future_usage: setup_future_usage
                        ? 'off_session'
                        : null,
                    tax_rate: tax_rate,
                }),
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                mode: 'cors',
            }
        );

        const response = await updatePaymentIntent.json();

        if (response) {
            if (response.errors) {
                return { error: 'There was an error verifying the upgrade licenses in your cart'}
            }
            localStorageService.set(LSKey.PaymentIntentClientSecret, response.cs);
            localStorageService.set(LSKey.PaymentIntentID, response.id);
            return {cs: response.cs, id: response.id}
        }
    } catch (error) {
        console.log('update payment intent err: ', error);
    }
};
