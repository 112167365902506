import { ActionType, Action } from '../actions/types';
import { LSKey, localStorageService } from '../utils/handleLocalStorage';

const LOCAL_STORAGE_PARSED: OmniStore.AuthInfo | null = localStorageService.get(LSKey.StoreAuth);

const initialAccountsState: OmniStore.GlobalAccountState = {
    accessToken: LOCAL_STORAGE_PARSED !== null ? LOCAL_STORAGE_PARSED.accessToken : undefined,
    accountInfo: undefined,
    accountsErrors: {},
    email: LOCAL_STORAGE_PARSED !== null ? LOCAL_STORAGE_PARSED.email : undefined,
    expandedUserInfo: undefined,
    expiresAt: LOCAL_STORAGE_PARSED !== null ? LOCAL_STORAGE_PARSED.expiresAt : undefined,
    has_auth: false,
    managedTeamLicenses: undefined,
    purchasesData: undefined,
    refreshToken: LOCAL_STORAGE_PARSED !== null ? LOCAL_STORAGE_PARSED.refreshToken : undefined,
    showSigninForm: false,
    username: LOCAL_STORAGE_PARSED !== null ? LOCAL_STORAGE_PARSED.username : undefined,
};

const accounts = (state = initialAccountsState, action: Action): OmniStore.GlobalAccountState => {
    switch (action.type) {
        case ActionType.SET_EXPANDED_USER_INFO: {
            return {
                ...state,
                expandedUserInfo: action.userinfo,
                has_auth: true,
                showSigninForm: false,
            };
        }
        case ActionType.SET_AUTH_TO_FALSE: {
            return {
                ...state,
                has_auth: false,
            };
        }
        case ActionType.SET_MANAGED_TEAM_LICENSES_INFO: {
            return {
                ...state,
                managedTeamLicenses: action.managedTeamLicenses,
            };
        }
        case ActionType.SET_PURCHASES_DATA: {
            return {
                ...state,
                purchasesData: action.purchasesData,
            };
        }
        case ActionType.SET_AUTH: {
            const userStroage = action.auth;
            return {
                ...state,
                ...userStroage,
                accountsErrors: {},
                has_auth: true,
            }
        }
        case ActionType.SIGN_IN_TO_ACCOUNTS: {
            if (!action.accountsError && action.accountInfo !== undefined) {
                const token = action.accountInfo.token;
                const userInfo = action.accountInfo.userInfo;

                const userStorage: OmniStore.AuthInfo = {
                    accessToken: token.access_token,
                    email: userInfo.email,
                    expiresAt: token.expires_at,
                    refreshToken: token.refresh_token,
                    username: userInfo.username,
                };

                localStorageService.set(LSKey.StoreAuth, userStorage);
                return {
                    ...state,
                    ...userStorage,
                    accountsErrors: {},
                    has_auth: true,
                };
            }
            const newAccountsErrors = state.accountsErrors;

            if (action.accountsError !== undefined) {
                newAccountsErrors[action.accountsError.code] =
                    action.accountsError.message;
            }

            return {
                ...state,
                accountsErrors: newAccountsErrors,
            };
        }
        case ActionType.SIGN_OUT_OF_ACCOUNTS: {
            const userStorage = {
                accessToken: undefined,
                email: undefined,
                expandedUserInfo: undefined,
                expiresAt: undefined,
                managedTeamLicenses: undefined,
                purchasesData: undefined,
                refreshToken: undefined,
                username: undefined,
            };

            localStorageService.remove(LSKey.StoreAuth);

            return {
                ...state,
                ...userStorage,
                has_auth: false,
            };
        }
        case ActionType.TOGGLE_SIGNIN_FORM: {
            return {
                ...state,
                showSigninForm: action.display,
            };
        }

        default:
            return state;
    }
};

export default accounts;
