export enum ActionType {
    ADD_ACCOUNTS_API_URL = 'ADD_ACCOUNTS_API_URL',
    // ADD_CURRENT_CARD = 'ADD_CURRENT_CARD',
    ADD_SUBSCRIPTION_TO_CART = 'ADD_SUBSCRIPTION_TO_CART',
    ADD_LICENSE_TO_CART = 'ADD_LICENSE_TO_CART',
    PUT_CART_FROM_SERVER = 'PUT_CART_FROM_SERVER',
    CLEAR_CART_FROM_SERVER = 'CLEAR_CART_FROM_SERVER',
    CLEAR_CART = 'CLEAR_CART',
    TOGGLE_SUBSCRIPTION_PAY_INTERVAL = 'TOGGLE_SUBSCRIPTION_PAY_INTERVAL',
    REMOVE_SUBSCRIPTION = 'REMOVE_SUBSCRIPTION',
    REMOVE_LICENSE = 'REMOVE_LICENSE',
    REMOVE_LICENSE_UPGRADE = 'REMOVE_LICENSE_UPGRADE',
    CONVERT_SUBSCRIPTION_TO_TEAM = 'CONVERT_SUBSCRIPTION_TO_TEAM',
    CONVERT_LICENSE_TO_TEAM = 'CONVERT_LICENSE_TO_TEAM',
    UPDATE_UPGRADE_QUANTITY_IN_CART = 'UPDATE_UPGRADE_QUANTITY_IN_CART',
    ADD_TO_SEAT_LIST = 'ADD_TO_SEAT_LIST',
    ADD_PURCHASE_API_URL = 'ADD_PURCHASE_API_URL',
    REFRESH_ACCESS_TOKEN = 'REFRESH_ACCESS_TOKEN',
    SAVE_CUSTOMER_INFO = 'SAVE_CUSTOMER_INFO',
    SAVE_SUBSCRIPTION_INFO = 'SAVE_SUBSCRIPTION_INFO',
    SET_API_URLS = 'SET_API_URLS',
    SET_EXPANDED_USER_INFO = 'SET_EXPANDED_USER_INFO',
    SET_GLOBAL_ERROR = 'SET_GLOBAL_ERROR',
    SET_MANAGED_TEAM_LICENSES_INFO = 'SET_MANAGED_TEAM_LICENSES_INFO',
    SET_PURCHASES_DATA = 'SET_PURCHASES_DATA',
    SET_STAFF_STATUS = 'SET_STAFF_STATUS',
    SET_STRIPE_PUBLISHABLE_KEY = 'SET_STRIPE_PUBLISHABLE_KEY',
    SET_TEAM_EDIT_ID = 'SET_TEAM_EDIT_ID',
    SIGN_IN_TO_ACCOUNTS = 'SIGN_IN_TO_ACCOUNTS',
    SIGN_OUT_OF_ACCOUNTS = 'SIGN_OUT_OF_ACCOUNTS',
    TOGGLE_SIGNIN_FORM = 'TOGGLE_SIGNIN_FORM',
    ADD_CARDS_FROM_STRIPE = 'ADD_CARDS_FROM_STRIPE',
    ADD_SUBSCRIPTIONS = 'ADD_SUBSCRIPTIONS',
    CLEAR_SUBSCRIPTIONS = 'CLEAR_SUBSCRIPTIONS',
    QUICK_QUANTITY_DISCOUNT = 'QUICK_QUANTITY_DISCOUNT',
    // VERIFIED_QUANTITY_DISCOUNT = 'VERIFIED_QUANTITY_DISCOUNT',
    SET_AUTH_TO_FALSE = 'SET_AUTH_TO_FALSE',

    ADD_LICENSE_UPGRADE_TO_CART = 'ADD_LICENSE_UPGRADE_TO_CART',
    SET_ELIGIBLE_UPGRADES = 'SET_ELIGIBLE_UPGRADES',

    SET_CART = 'SET_CART',
    SET_AUTH = 'SET_AUTH',
}

//STRIPE ACTION TYPES
interface SetStripePublishableKey {
    type: ActionType.SET_STRIPE_PUBLISHABLE_KEY;
    publishableKey: string;
}

export type StripeAction = SetStripePublishableKey;

//ACCOUNT ACTION TYPES
interface SetPurchaseData {
    type: ActionType.SET_PURCHASES_DATA;
    purchasesData: OmniStore.PurchasesData;
}

interface SetAccountInfo {
    type: ActionType.REFRESH_ACCESS_TOKEN;
    accountInfo: OmniStore.AccountInfo;
    accountsError: OmniStore.AccountsError;
}

interface SetExpandedUserInfo {
    type: ActionType.SET_EXPANDED_USER_INFO;
    userinfo: OmniStore.UserInfoExpanded;
}

interface SetManagedTeamLicenseInfo {
    type: ActionType.SET_MANAGED_TEAM_LICENSES_INFO;
    managedTeamLicenses: OmniStore.ManagedTeamLicenses;
}

interface SignInToAccounts {
    type: ActionType.SIGN_IN_TO_ACCOUNTS;
    accountInfo?: OmniStore.AccountInfo;
    accountsError?: OmniStore.AccountsError;
}

interface SignOutOfAccounts {
    type: ActionType.SIGN_OUT_OF_ACCOUNTS;
}

interface ToggleSignInForm {
    type: ActionType.TOGGLE_SIGNIN_FORM;
    display: boolean;
}

interface SetAuth {
    type: ActionType.SET_AUTH;
    auth: OmniStore.UserInfo;
}

interface SetAuthToFalse {
    type: ActionType.SET_AUTH_TO_FALSE;
}


export type AccountAction = 
    | SetAccountInfo
    | SetExpandedUserInfo
    | SetManagedTeamLicenseInfo
    | SignInToAccounts
    | SignOutOfAccounts
    | ToggleSignInForm
    | SetAuthToFalse
    | SetPurchaseData
    | SetAuth

//CART ACTION TYPES
interface ClearCart {
    type: ActionType.CLEAR_CART;
}

interface PutCartFromServer {
    type: ActionType.PUT_CART_FROM_SERVER;
    item: OmniStore.CartFromAPI;
}

interface ClearCartFromServer {
    type: ActionType.CLEAR_CART_FROM_SERVER
}

interface TogglePayInterval {
    type: ActionType.TOGGLE_SUBSCRIPTION_PAY_INTERVAL;
    item: OmniStore.SubscriptionCartItem;
}

interface AddSubscriptionToCart {
    type: ActionType.ADD_SUBSCRIPTION_TO_CART;
    item: OmniStore.SubscriptionCartItem;
}

interface ConvertSubscriptionToTeam {
    type: ActionType.CONVERT_SUBSCRIPTION_TO_TEAM;
    item: OmniStore.SubscriptionCartItem;
}

interface RemoveSubscription {
    type: ActionType.REMOVE_SUBSCRIPTION;
    item: OmniStore.SubscriptionCartItem;
}

interface AddLicenseToCart {
    type: ActionType.ADD_LICENSE_TO_CART;
    item: OmniStore.LicenseCartItem;
}

interface ConvertLicenseToTeam {
    type: ActionType.CONVERT_LICENSE_TO_TEAM;
    item: OmniStore.LicenseCartItem;
}

interface RemoveLicense {
    type: ActionType.REMOVE_LICENSE;
    item: OmniStore.LicenseCartItem;
}

interface UpdateUpgradeSeatQuantity {
    type: ActionType.UPDATE_UPGRADE_QUANTITY_IN_CART;
    item: OmniStore.LicenseUpgradeCartItem;
}

interface RemoveLicenseUpgrade {
    type: ActionType.REMOVE_LICENSE_UPGRADE;
    item: OmniStore.LicenseCartItem;
}

interface UpdateQuantityDiscount {
    type: ActionType.QUICK_QUANTITY_DISCOUNT;
}

interface SetCart {
    cart: OmniStore.GlobalCartState["cart"];
    type: ActionType.SET_CART;
}

export type CartAction =
    | ClearCart
    | ClearCartFromServer
    | PutCartFromServer
    | TogglePayInterval
    | AddSubscriptionToCart
    | ConvertSubscriptionToTeam
    | RemoveSubscription
    | AddLicenseToCart
    | ConvertLicenseToTeam
    | RemoveLicense
    | RemoveLicenseUpgrade
    | UpdateUpgradeSeatQuantity
    | UpdateQuantityDiscount
    | SetCart

//SUBSCRIPTION ACTION TYPES
interface ClearSubscriptions {
    type: ActionType.CLEAR_SUBSCRIPTIONS;
}

interface SetInitialSubscriptions {
    type: ActionType.ADD_SUBSCRIPTIONS;
    subscriptions: Array<OmniStore.Subscription>;
}

export type SubscriptionAction = 
    | ClearSubscriptions
    | SetInitialSubscriptions

interface AddLicenseUpgradeToCart {
    type: ActionType.ADD_LICENSE_UPGRADE_TO_CART;
    item: OmniStore.LicenseUpgradeCartItem;
}

interface SetEligibleLicenseUpgrades {
    type: ActionType.SET_ELIGIBLE_UPGRADES;
    upgrades: OmniStore.EligibileUpgradeData;
}

export type LicenseUpgradeAction = 
    | AddLicenseUpgradeToCart
    | SetEligibleLicenseUpgrades

export type Action = 
    | StripeAction
    | AccountAction
    | CartAction
    | SubscriptionAction
    | LicenseUpgradeAction
