import { REGISTER_EP } from '../constants/PurchaseEndpoints';

interface PostRegister {
    accessToken: string;
    customer_id: string;
    payment_intent_id: string;
    purchaseApiUrl: string;
}

interface PostRegisterResponse {
    message?: string;
    should_trigger_refund?: boolean;
    status?: 'success' | 'error';
    errors?: Array<{code: number, message: string}>;
}

const postRegisterLicense = async ({
    accessToken,
    customer_id,
    payment_intent_id,
    purchaseApiUrl,
}: PostRegister): Promise<PostRegisterResponse> => {
    try {
        const registerLicense = await fetch(`
            ${purchaseApiUrl}${REGISTER_EP({
                customer_id: customer_id,
                payment_intent_id: payment_intent_id,
            })}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                mode: 'cors',
            }
        );

        const response = await registerLicense.json();

        if (response) {
            if (response.errors) {
                return {errors: response.errors}
            }
            return response;
        }
    } catch (error) {
        console.log('error: ', error)
    }
};

export default postRegisterLicense;
