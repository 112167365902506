import * as React from 'react';
import styled from 'styled-components/macro';

import * as vars from '../styles/variables';

declare const voidPromise: (e: React.SyntheticEvent) => Promise<void>;
declare const voidVoid: () => void;

interface ButtonProps {
    alignRight?: boolean;
    buttonType?: string;
    center?: boolean;
    children?: React.ReactNode;
    danger?: boolean;
    disabled?: boolean;
    inline?: boolean;
    minWidth?: string;
    onClick?: typeof voidPromise | typeof voidVoid;
    selected?: boolean;
    showLoading?: boolean;
    someMargin?: boolean;
    type?: 'submit' | 'reset' | 'button';
    uppercase?: boolean;
    value?: string;
}

interface SideBySideProps {
    children?: React.ReactNode;
    flexStart?: boolean;
    minWidth?: string;
    spaceMiddle?: boolean;
}

const SideBySideHolder = styled.div<SideBySideProps>`
    display: flex;
    flex-direction: row;
    justify-content: ${(props): string =>
        props.spaceMiddle ? 'space-between' : 'center'};
    justify-content: ${(props): string =>
        props.flexStart ? 'flex-start' : 'center'};
    flex-wrap: wrap;
`;

const StyledButton = styled.button<ButtonProps>`
    padding: 10px 20px;
    height: initial;
    line-height: 22px;
    font-family: quatro, sans-serif;
    background: ${(props): string => (props.danger ? 'red' : vars.whiteColor)};
    color: ${(props): string => (props.danger ? vars.whiteColor : vars.blackColor)};
    color: ${(props): string => (props.selected ? vars.whiteColor : vars.blackColor)};
    background: ${(props): string =>
        props.selected ? 'rgba(48, 140, 255, 1.00)' : vars.whiteColor};
    border: ${(props): string | undefined =>
        props.selected ? '1px solid rgba(17, 61, 147, 1.00)' : undefined};
    border: 1px solid #909090;
    border-radius: 6px;
    cursor: pointer;
    display: block;
    display: ${(props): string => (props.inline ? 'inline' : 'block')};
    font-size: 16px;
    font-weight: 500;
    margin: ${(props): string => (props.center ? '10px auto' : '10px 0')};
    margin: ${(props): string | undefined =>
        props.someMargin ? '10px 10px' : undefined};
    margin: ${(props): string | undefined =>
        props.alignRight ? '10px 10px 10px auto' : undefined};
    padding: 10px 20px;
    text-transform: ${(props): string =>
        props.uppercase ? 'uppercase' : 'initial'};
    min-width: ${(props): string | undefined => props.minWidth};

    &:disabled {
        background-color: rgba(0, 0, 0, 0.05);
        color: ${vars.darkGray};
        border: 1px solid rgba(144, 144, 144, 0.95);
        background-color: ${vars.disabledLightGray};
        color: ${vars.blackColor};
        font-weight: 400;
        cursor: initial;
    }

    &:disabled:hover {
        background-color: rgba(0, 0, 0, 0.05);
        color: ${vars.darkGray};
    }

    &:disabled:active {
        background-color: rgba(0, 0, 0, 0.05);
        color: ${vars.darkGray};
    }

    &:hover {
        cursor: pointer;
        color: ${vars.blackColor};
        background: ${(props): string =>
            props.danger ? 'red' : 'rgba(48, 140, 255, 0.69)'};
    }

    &:active:hover {
        color: ${vars.darkGray};
    }

    &:active {
        background: ${(props): string =>
            props.danger
                ? 'rgba(255, 0, 0, 0.71)'
                : 'rgba(48, 140, 255, 1.00)'};
        color: ${vars.whiteColor};
    }
`;

const SideBySide: React.FC<SideBySideProps> = ({
    children,
    ...props
}: SideBySideProps): JSX.Element => {
    return <SideBySideHolder {...props}>{children}</SideBySideHolder>;
};

const Button: React.FC<ButtonProps> = ({
    children,
    ...props
}: ButtonProps): JSX.Element => {
    return (
        <StyledButton
            disabled={props.disabled}
            className={
                props.showLoading === true
                    ? 'ld-ext-right running'
                    : 'ld-ext-right'
            }
            {...props}
        >
            {children}
            <div className='ld ld-ring ld-spin'></div>
        </StyledButton>
    );
};

export { Button, SideBySide };
