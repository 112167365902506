import  * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import styled, { css } from 'styled-components';

import DiscountBlock from './DiscountBlock';
import UpgradeForm from './UpgradeForm';
import * as vars from '../styles/variables';

import { addLicenseUpgradeToCart } from '../actions/cart';
import getEligibleUpgrades from '../utils/getUpgradeItems';

interface StyleProps {
    upgradeTypes: number;
}

const ContentWrapper = styled.div`
    > div > a {
        display: flex;
        justify-content: center;
    }
    > p {
        text-align: center;
        padding: 0 20px;
        margin-top: 0;
    }
    > p, a {
        color: ${vars.whiteColor};
    }
`;

const NoUpgradesAvailable = styled.div`
    display: block;
    text-align: center;
    
    > a {
        padding: 20px;
        color: ${vars.whiteColor};
    }
`;

const ContentHeader = styled.div`
    font-size: 20px;
    font-weight: 600;
    padding: 20px;
    color: ${vars.whiteColor};
`;

const UpgradeContainer = styled.div<StyleProps>`
    ${(props) => props.upgradeTypes > 1 ? 
        css`
            display: grid;
            grid-template-areas: 
                "Standard Pro"
            ;
            gap: 15px;
            justify-items: center;
        `
        :
        css`
            display: block;
            margin: 0 150px;

            @media screen and (max-width: 600px) {
                display: grid;
                grid-template-areas:
                    "Standard"
                    "Pro"
                ;
                margin: 0;
                gap: 15px;
                justify-items: center;
            }
        `
    }
`;

interface UpgradeTracker {
    Standard: { [regID: string]: OmniStore.UpgradeInfo },
    Pro:  { [regID: string]: OmniStore.UpgradeInfo },
    Current: { [regID: string]: OmniStore.UpgradeInfo },
}

interface UpgradeContentProps {
    backgroundColor: string;
    color: string;
    platform: string;
    productVersion: string;
    shortName: string;
}

const UpgradeContent = (props: UpgradeContentProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {trackEvent} = useMatomo();

    const accessToken = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.accessToken);
    const purchaseApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.purchaseAPIURL);

    const accounts = useSelector((state: OmniStore.GlobalReduxState) => state.accounts);
    const cartItems = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.cart }));
    const upgrades = useSelector((state: OmniStore.GlobalReduxState) => state.upgrades[props.shortName]);

    const [currentVersion, setCurrentVersion] = React.useState<boolean>(false);
    const [currentVersionUpgrade, setCurrentVersionUpgrade] = React.useState<Array<OmniStore.UpgradeInfo>>([]);
    const [inCart, setInCart] = React.useState<OmniStore.InCartUpgradeInfo>({});
    const [proUpgrades, setProUpgrades] = React.useState<Array<OmniStore.UpgradeInfo>>([]);
    const [stdUpgrades, setStdUpgrades] = React.useState<Array<OmniStore.UpgradeInfo>>([]);
    const [upgradeTracker, setUpgradeTracker] = React.useState<UpgradeTracker>({
        Current: {},
        Pro: {},
        Standard: {},
    });

    const current_major_version = {
        omnifocus: "OmniFocus 4",
        omnigraffle: "OmniGraffle 7",
        omnioutliner: "OmniOutliner 5",
        omniplan: "OmniPlan 4",
    };

    
    const updateEligibleUpgrades = () => {
        getEligibleUpgrades({accessToken, purchaseApiUrl});
    }

    window.addEventListener('storage', updateEligibleUpgrades)

    React.useEffect(() => {
        // remove eligible upgrades after user signs out
        if (!accessToken) {
            setProUpgrades([]);
            setStdUpgrades([]);
            setCurrentVersionUpgrade([]);
        }
    }, [ accessToken ]);

    React.useEffect(() => {
        //find items already added to cart
        const proRegistrationIDs = proUpgrades.map((item) => item.registration_identifier);
        const stdRegistrationIDs = stdUpgrades.map((item) => item.registration_identifier);
        const currentRegistrationIDs = currentVersionUpgrade.map((item) => item.registration_identifier);
        const registrationIDs = [...proRegistrationIDs, ...stdRegistrationIDs, ...currentRegistrationIDs];
        const uniqueRegistrationIDs = registrationIDs.filter((id, pos) => 
            registrationIDs.indexOf(id) === pos); //remove duplicate registration ids

        cartItems.cart.upgrades.map((value) => {
            uniqueRegistrationIDs.map((id) => {
                if (id === value.registration_identifier) {
                    setInCart((prevCart) => ({
                        ...prevCart,
                        [value.registration_identifier]: {
                            ...prevCart[value.registration_identifier],
                            [value.standardOrProUpgrade]: value.quantity,
                        },
                    }));
                }
            })
        })
    }, [
        stdUpgrades,
        proUpgrades,
        currentVersionUpgrade,
        cartItems.cart.upgrades.length,
        accounts.purchasesData
    ]);

    React.useEffect(() => {
        setProUpgrades([]);
        setStdUpgrades([]);
        setCurrentVersionUpgrade([]);
        
        if (upgrades) {
            const refineProductName = (name: string) => {
                const name_array = name.split("_");
                const omni_product = props.shortName === 'omnifocus'
                    ? 'OmniFocus'
                    : props.shortName === 'omnioutliner'
                    ? 'OmniOutliner'
                    : props.shortName === 'omnigraffle'
                    ? 'OmniGraffle'
                    : props.shortName === 'omniplan'
                    ? "OmniPlan"
                    : ""
                ;
                
                const std_idx = name_array.indexOf("STD");
                if (std_idx > -1) {
                    name_array.splice(std_idx);
                }   
                name_array.shift();
                const mac_idx = name_array.indexOf("MAC");
                name_array.splice(mac_idx, mac_idx-1);
                const refined_product_name = name_array.join(" ");
                return refined_product_name.replace(name_array[0], omni_product);
            };

            upgrades.forEach((item) => {
                for (const product in item) {
                    const product_upgrade = product.includes('PRO') ? 'pro' : 'std';
                    const eligible_upgrade_items = item[product];

                    eligible_upgrade_items.forEach((prod) => {
                        const product_name = refineProductName(prod.active_product_ids[0]);
                        const current_std_version = current_major_version[props.shortName] === product_name;
                        const upgrade_item = {
                            ...prod,
                            product_name,
                            upgrade_to: product,
                        };
                        
                        if (product_upgrade === 'std') {
                            setStdUpgrades((prevUpgrades) => [ ...prevUpgrades, upgrade_item])
                        } else {
                            if (current_std_version) {
                                setCurrentVersion(true);
                                setCurrentVersionUpgrade((prevUpgrades) => [ ...prevUpgrades, upgrade_item]);
                            } else {
                                setProUpgrades((prevUpgrades) => [ ...prevUpgrades, upgrade_item]);
                            }
                        }
                    })
                }
            });
        }
    }, [upgrades])


    const handleChange = (e: React.FormEvent<HTMLInputElement>, product: OmniStore.UpgradeInfo, upgradeType: string) => {
        const productName = e.currentTarget.name;
        const quantity = Number(e.currentTarget.value);
        const upgrade_to = upgradeTracker[upgradeType];

        if (quantity === 0) {
            delete upgrade_to[productName];
            setUpgradeTracker({...upgradeTracker});
        } else {
            if (quantity > product.upgrade_quantity) {
                //return and display error box
            } else {
                upgrade_to[productName] = {
                    ...product,
                    quantity,
                }
                setUpgradeTracker({
                    ...upgradeTracker, 
                    [upgradeType]: upgrade_to,
                });
            }
        }
    }

    const addUpgradesToCart = async (upgradeType: string) => {
        const items_to_upgrade = upgradeTracker[upgradeType];

        for (const product in items_to_upgrade) {
            const item = items_to_upgrade[product];

            dispatch(addLicenseUpgradeToCart({
                item: {
                    dateAddedToCart: Date.now(),
                    displayName: item.product_name,
                    quantity: item.quantity,
                    registration_identifier: item.registration_identifier,
                    seatsAvailableToUpgrade: item.upgrade_quantity,
                    shortName: props.shortName,
                    standardOrProUpgrade: upgradeType === 'Standard'
                        ? 'STANDARD'
                        : 'PRO'
                    ,
                    upgradePrice: item.upgrade_price,
                    upgradeTo: item.upgrade_to,
                },
            }))
            trackEvent({
                action: 'Cart Change',
                category: 'Ecommerce',
                name: 'Added to cart: ' + item.product_name, // optional
                value: item.unit_upgrade_price/100,
            });
        }
        navigate('/cart');
    }

    if (!accessToken) {
        return (
            <DiscountBlock 
            backgroundColor={props.backgroundColor}
                blockHeader={'Save up to 50% with upgrade discounts'}
                color={props.color}
                description={intl.formatMessage({
                    id: 'upgradeDiscountDescription'
                })}
                offtw={false}
                product={props.shortName as 'omnifocus'}
                signIn={intl.formatMessage({
                    id: 'SignInOmniAccount'
                })}
            />
        )
    } else {
        if (!upgrades) {
            return (
                <NoUpgradesAvailable>
                    <ContentHeader>No upgrades available for registered purchases.</ContentHeader>
                    <a href="/register-license">Register a license key</a>
                </NoUpgradesAvailable>
            )
        } else {
            return (
                <ContentWrapper>
                    <ContentHeader>
                        <FormattedMessage id="availableUpgradeOptions" />
                    </ContentHeader>
                    <p style={{textAlign: "left"}}>
                        <FormattedMessage 
                            id="partialSeatUpgrade" 
                            values={{
                                OmniAccounts: (
                                    <a href='https://accounts.omnigroup.com'>
                                        {intl.formatMessage({
                                            id: 'omniAccountsLinkText',
                                        })}
                                    </a>
                                    
                                )
                            }}
                        />
                    </p>
                    <p>
                        <a href="https://store.omnigroup.com/register-license">Register a license key</a>
                    </p>
                    <UpgradeContainer upgradeTypes={stdUpgrades.length + proUpgrades.length} >
                        { stdUpgrades.length > 0 && (
                            <UpgradeForm
                                backgroundColor={props.backgroundColor}
                                color={props.color}
                                gridArea='Standard'
                                inCart={inCart}
                                platform={props.platform}
                                productVersion={props.productVersion}
                                currentVersion={false}
                                shortName={props.shortName}
                                standardOrPro={true}
                                fullPrice={stdUpgrades[0].unit_price}
                                upgradeItems={stdUpgrades}
                                upgradePrice={stdUpgrades[0].upgrade_price}
                                addUpgradesToCart={addUpgradesToCart}
                                handleChange={handleChange}
                                lienseType={props.shortName === 'omnioutliner' ? 'Essentials' : 'Standard'}
                            />
                        )}
                        {/* also need to add partial upgrades to pro upgrade total! */}
                        {proUpgrades.length > 0 ? (
                            <UpgradeForm
                                backgroundColor={props.backgroundColor}
                                color={props.color}
                                gridArea='Pro'
                                inCart={inCart}
                                currentVersion={currentVersion}
                                currentVersionUpgrade={currentVersionUpgrade}
                                lienseType="Pro"
                                platform={props.platform}
                                productVersion={props.productVersion}
                                shortName={props.shortName}
                                standardOrPro={proUpgrades.length > 0}
                                fullPrice={proUpgrades[0].unit_price}
                                upgradePrice={proUpgrades[0].upgrade_price}
                                upgradeItems={proUpgrades}
                                addUpgradesToCart={addUpgradesToCart}
                                handleChange={handleChange}
                            />
                        ) : (proUpgrades.length === 0 && currentVersionUpgrade.length > 0) ? (
                            <UpgradeForm
                                backgroundColor={props.backgroundColor}
                                color={props.color}
                                gridArea='Pro'
                                inCart={inCart}
                                currentVersion={currentVersion}
                                currentVersionUpgrade={currentVersionUpgrade}
                                lienseType="Pro"
                                platform={props.platform}
                                productVersion={props.productVersion}
                                shortName={props.shortName}
                                standardOrPro={false}
                                fullPrice={currentVersionUpgrade[0].unit_price}
                                upgradePrice={currentVersionUpgrade[0].upgrade_price}
                                addUpgradesToCart={addUpgradesToCart}
                                handleChange={handleChange}
                            /> 
                            ) : null
                        }
                    </UpgradeContainer>
                </ContentWrapper>
            )
        }
    } 
}

export default UpgradeContent;
