import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import styled from 'styled-components';

import BuyUpgradeToggle from './BuyUpgradeToggle';
import DiscountBlock from './DiscountBlock';
import { BuyLicenseBlock, MobileLicenseBlock} from './BuyLicense';
import UpgradeLicense from './UpgradeLicense';
import { addLicenseToCart } from '../actions/cart';

import * as vars from '../styles/variables';

interface StyleProps {
    color?: string;
    backgroundColor?: string;
    borderHighlight?: boolean;
    product?: string;
}

const LicenseWrapper = styled.div`
    grid-area: license;
    width: 660px;
    border-radius: 7px 7px 0 0;

    > h1 {
        text-align: center;
        font-weight: 500;
    }
    > p {
        margin: 0 auto 2.5em;
    }
    > a {
        text-align: center;
    }

    @media screen and (max-width: 700px) {
        height: 100%;
        width: auto;
    }
`;

const LicenseBlockContainer = styled.div<StyleProps>`
    border: 5px solid ${props => props.color};
    padding: 30px;
    border-radius: 10px;

    @media screen and (max-width: 600px) {
        width: 300px;
        margin: auto;
    }
`;

const SpecialOffer = styled.p`
    display: block;
    justify-content: center;
    padding: 7px;
    border-radius: 5px;
    background-color: #ECE3FF;

    > span {
        font-weight: 500;
    }

    @media screen and (max-width: 700px) {
        width: 300px;
    }
`;

const OutlinerMessage = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    color: ${vars.whiteColor};

    > span {
        margin: 0 10px;
    }
`;

const MobileLicenseBlockContainer = styled.div`
    display: none;

    @media screen and (max-width: 700px) {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin: auto;
        align-items: center;
        width: 300px;
    }
`;

interface LicenseBlockProps extends OmniStore.LicenseCartItem {
    backgroundColor: string;
    color: string;
    dateAddedToCart: number;
    features?: Array<string>;
    header: Array<string>;
    omnioutlinerMessage?: string;
    platform: string;
    products: Array<OmniStore.ProductInfo>;
    productVersion: string;
    shortName: 'omnigraffle' | 'omnifocus' | 'omnioutliner' | 'omniplan';
    specialOffer?: string;
    universalIOSCounterparts?: Array<string>; //could be OMNIPLAN_4_IOS_STD or OMNIPLAN_4_IOS_PRO, and only OmniPlan right now
}

const LicenseBlock: React.FC<LicenseBlockProps> = (props) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();
    const { trackEvent } = useMatomo();

    const initialQuantity = props.quantity ? props.quantity : 2;
    
    const accounts = useSelector((state: OmniStore.GlobalReduxState) => state.accounts);
    const cartItems = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.cart }));
    const licenseUpgrades = useSelector((state: OmniStore.GlobalReduxState) => ({upgrades: state.upgrades}));


    const [finishedLoading, setFinished] = useState(false);
    const [inCart, setInCart] = useState<boolean>(false);
    const [proQuantity, setProQuantity] = useState<number>(initialQuantity);
    const [selection, setSelection] = useState<'STANDARD' | 'PRO'>(props.standardOrPro);
    const [standardQuantity, setStandardQuantity] = useState<number>(initialQuantity);
    const [standardQuantityError, setStandardQuantityError] = useState<boolean>(false);
    const [proQuantityError, setProQuantityError] = useState<boolean>(false);
    const [upgradeContent, setUpgradeContent] = useState<boolean>(false);
    const [initalContentLoad, setInitialContent] = useState<boolean>(false);

    useEffect(() => {
        if (standardQuantity <= 0) {
            // setStandardQuantity(1)
            setStandardQuantityError(true);
        } else if (proQuantity <= 0) {
            // setProQuantity(1)
            setProQuantityError(true);
        } else {
            setStandardQuantityError(false);
            setProQuantityError(false);
        }

    }, [standardQuantity, proQuantity]);

    useEffect(() => {
        cartItems.cart.licenses.map((value) => {
            if (value.displayName === props.displayName) {
                setInCart(true);
            }
            return null;
        });
        setFinished(true);
    }, [
        cartItems,
        props.displayName,
        accounts.purchasesData
    ])

    useEffect(() => {
        const omniProduct = props.shortName;
        const availableUpgrades = licenseUpgrades.upgrades[omniProduct];
        if (availableUpgrades && (initalContentLoad === false)) {
            setUpgradeContent(true);
            setInitialContent(true);
        }
    }, [licenseUpgrades])

    const addLicenseToCartHandler = async (productID: string, price: number) => {
        dispatch(
            addLicenseToCart({
                item: {
                    dateAddedToCart: Date.now(),
                    displayName: props.displayName,
                    price: price,
                    productID,
                    quantity: selection === 'PRO' 
                        ? proQuantity 
                        : standardQuantity,
                    shortName: props.shortName,
                    standardOrPro: selection,
                },
            })
        );

        trackEvent({
            action: 'Cart Change',
            category: 'Ecommerce',
            name: 'Added to cart: ' + props.displayName, // optional
            value: price
        });
        navigate('/cart');
    };

    return (
        <LicenseWrapper>
            {props.specialOffer && (
                <SpecialOffer>
                    <span>Special Offer:</span> {props.specialOffer}
                </SpecialOffer>
            )}
            <LicenseBlockContainer color={props.color}>
                <BuyUpgradeToggle 
                    color={props.color}
                    shortName={props.shortName}
                    upgradeContent={upgradeContent}
                    setUpgradeContent={setUpgradeContent}
                />
                { upgradeContent ? (
                    <>
                        <UpgradeLicense
                            backgroundColor={props.backgroundColor}
                            color={props.color}
                            platform={props.platform}
                            productVersion={props.productVersion}
                            shortName={props.shortName}
                        />
                        {props.omnioutlinerMessage && (
                            <OutlinerMessage>
                                <span>¹</span>
                                <div>{props.omnioutlinerMessage}</div>
                            </OutlinerMessage>
                        )}
                    </>
                ) : (
                    <>
                        <MobileLicenseBlockContainer>
                            {props.products.map((license) => (
                                <MobileLicenseBlock
                                    key={license.vending_product_id}
                                    addLicenseToCartHandler={addLicenseToCartHandler}
                                    backgroundColor={props.backgroundColor}
                                    color={props.color}
                                    features={props.features}
                                    finishedLoading={finishedLoading}
                                    header={license.name}
                                    inCart={inCart}
                                    licenseType={license.licenseType}
                                    platform={props.platform}
                                    product={props.shortName}
                                    productID={license.vending_product_id}
                                    price={license.price}
                                    proQuantityError={proQuantityError}
                                    quantity={license.licenseType === 'PRO' ? proQuantity : standardQuantity}
                                    setProQuantity={setProQuantity}
                                    setSelection={setSelection}
                                    setStandardQuantity={setStandardQuantity}
                                    standardQuantityError={standardQuantityError}
                                />
                            ))}
                            <DiscountBlock
                                className='mobile'
                                backgroundColor={props.backgroundColor}
                                blockHeader={'Save up to 50% with upgrade discounts'}
                                color={props.color}
                                description={intl.formatMessage({
                                    id: 'upgradeDiscountDescription'
                                })}
                                offtw={false}
                                product={props.shortName}
                                signIn={intl.formatMessage({
                                    id: 'SignInOmniAccount'
                                })}
                            />
                        </MobileLicenseBlockContainer>
                        <BuyLicenseBlock
                            backgroundColor={props.backgroundColor}
                            color={props.color}
                            dateAddedToCart={props.dateAddedToCart}
                            displayName={props.displayName}
                            features={props.features}
                            finishedLoading={finishedLoading}
                            header={props.header}
                            inCart={inCart}
                            platform={props.platform}
                            products={props.products}
                            proQuantity={proQuantity}
                            proQuantityError={proQuantityError}
                            selection={selection}
                            shortName={props.shortName}
                            standardQuantity={standardQuantity}
                            standardQuantityError={standardQuantityError}
                            usingUpgradePricing={false}

                            addLicenseToCartHandler={addLicenseToCartHandler}
                            setProQuantity={setProQuantity}
                            setSelection={setSelection}
                            setStandardQuantity={setStandardQuantity}
                            setStandardQuantityError={setStandardQuantityError}
                            setProQuantityError={setProQuantityError}
                        />
                    </>
                )} 
            </LicenseBlockContainer>
        </LicenseWrapper>
    );
}

export default LicenseBlock;
