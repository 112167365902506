import * as React from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import styled from 'styled-components';

const InputRowWrap = styled.div`
    margin: 10px 0;
`;

const InputField = styled.input`
    width: 100%;
`;

const Grouping = styled.div`
    margin: 20px 0;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px auto;
`;

interface BillingFormProps {
    formFor: string;
    addressLineOne: string;
    addressLineTwo: string;
    city: string;
    country: string;
    region: string;
    postalCode: string;

    changeAddressLineOne: React.Dispatch<React.SetStateAction<string>>;
    changeAddressLineTwo: React.Dispatch<React.SetStateAction<string>>;
    selectCountry: React.Dispatch<React.SetStateAction<string>>;
    selectRegion: React.Dispatch<React.SetStateAction<string>>;
    addCity: React.Dispatch<React.SetStateAction<string>>;
    addPostalCode: React.Dispatch<React.SetStateAction<string>>;
}

const BillingAddessForm: React.FC<BillingFormProps> = (props) => (
    <>
        <InputRowWrap>
            <label htmlFor={`${props.formFor}-address1`}>Address:</label>
            <InputField
                id={`${props.formFor}-address1`}
                type='text'
                value={props.addressLineOne}
                onChange={(e: React.FormEvent<HTMLInputElement>) => 
                    props.changeAddressLineOne(e.currentTarget.value)}
            />
            <InputField
                id={`${props.formFor}-address1`}
                type='text'
                value={props.addressLineTwo}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    props.changeAddressLineTwo(e.currentTarget.value)
                }
            />
        </InputRowWrap>
        <Grouping>
            <InputRowWrap>
                <label htmlFor={`${props.formFor}-country`}>Country:</label>
                <CountryDropdown
                    id={`${props.formFor}-country`}
                    value={props.country}
                    valueType='short'
                    onChange={(e: string) => props.selectCountry(e)}
                />
            </InputRowWrap>
            <InputRowWrap>
                <label htmlFor={`${props.formFor}-region`}>Region:</label>
                <RegionDropdown
                    country={props.country}
                    value={props.region}
                    id={`${props.formFor}-region`}
                    valueType='short'
                    countryValueType='short'
                    onChange={(e: string) => props.selectRegion(e)}
                />
            </InputRowWrap>
        </Grouping>
        <InputRowWrap>
            <Row>
                <div style={{ margin: '0', width: '49%' }}>
                    <label htmlFor={`${props.formFor}-city`}>City:</label>
                    <InputField
                        type='text'
                        id={`${props.formFor}-city`}
                        value={props.city}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => 
                            props.addCity(e.currentTarget.value)}
                    />
                </div>
                <div style={{ margin: '0', width: '49%' }}>
                    <label htmlFor={`${props.formFor}-postal-code`}>Postal code: </label>
                    <InputField
                        type='text'
                        id={`${props.formFor}-postal-code`}
                        value={props.postalCode}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            props.addPostalCode(e.currentTarget.value)}
                    />
                </div>
            </Row>
        </InputRowWrap>
    </>
);

export default BillingAddessForm;