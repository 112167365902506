import { CREATE_SUBSCRIPTION_EP } from '../constants/PurchaseEndpoints';
import { Metadata, PaymentMethod } from '@stripe/stripe-js';

interface Plans {
    plan_id: string;
    quantity: number;
}
interface PostSubscribeProps {
    accessToken: string;
    customer_id: string;
    email: string;
    jurisdiction?: string;
    metadata?: Metadata;
    payment_method_id?: string | PaymentMethod;
    plans: Array<Plans>;
    purchaseApiUrl: string;
    tax_rate: string;
}
const postSubscribe = async ({
    accessToken,
    customer_id,
    email,
    metadata,
    jurisdiction,
    payment_method_id,
    plans,
    purchaseApiUrl,
    tax_rate,
}: PostSubscribeProps): Promise<boolean> => {
    try {
        const subscriptionPost = await fetch(`${purchaseApiUrl}${CREATE_SUBSCRIPTION_EP({
            customer_id
        })}`, 
            {
                body: JSON.stringify({
                    customer_id,
                    email,
                    jurisdiction: jurisdiction ? jurisdiction : null,
                    metadata: metadata ? metadata : null,
                    payment_method_id,
                    plans,
                    tax_rate,
                }),
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                mode: 'cors',
        });

        const response = await subscriptionPost.json();

        if (response.errors) {
            return false;
        }
        return true;

    } catch (error) {
        console.log('unable to post subscription: ', error)
    }
}

export default postSubscribe;
