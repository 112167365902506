import * as React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';

import ReceiptInformation from './ReceiptDescription';

const TotalCost = styled.div`
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    max-width: 540px;
`;

const Grouping = styled.div`
    margin: 5px 0 10px 0;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
        margin: 50px 0 5px 0;
        font-style: italic;

        &:first-of-type {
            /* margin-top: 30px; */
            margin-top: 0;
        }
    }
`;

const ProductPrice = styled.div`
    width: 30%;
    font-size: 14px;
    text-align: right;
`;

const Figures = styled.span`
    flex-grow: 1;
    min-width: 100px;
`;

const ProductName = styled.div`
    font-size: 16px;
    font-weight: 600;
    width: 70%;
`;

const TotalAppWithTaxCost = styled.div`
    display: flex;
    flex-direction: row;
    border-top: 1px solid #000;
    margin: 10px 0 0;
    padding: 10px 0;
    width: 100%;
`;

const Subscript = styled.div`
    color: #808080;
    width: 100%;
    font-size: 12px;
    line-height: 1.5;
`;

interface ReceiptProps {
    taxRate: string;
}

const Receipt: React.FC<ReceiptProps> = (props) => {
    const cartFromAPI = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.serverCart,}))
    const cartItems = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.cart}));
    const productVersion = useSelector((state: OmniStore.GlobalReduxState) => state.currentMajorVersions);

    const [subscriptionEstimate, setSubscriptionEstimate] = React.useState<number>(0);

    const licensesInCart = cartItems.cart.licenses;
    const upgradesInCart = cartItems.cart.upgrades;
    const subscriptionsInCart = cartItems.cart.subscriptions;
    const { taxRate } = props;
    const taxrate = parseFloat(taxRate);

    React.useEffect(() => {
        const subtotal = subscriptionsInCart.reduce((accrued, item) => {
            if (item.payInterval === 'MONTHLY') {
                return accrued += (item.monthlyPrice * item.quantity)/100;
            } else if (item.payInterval === 'YEARLY') {
                return accrued += (item.yearlyPrice * item.quantity)/100;
            }
        }, 0);

        setSubscriptionEstimate(subtotal);
    }, [cartItems]);

    const subscriptionTax = subscriptionEstimate * taxrate;
    const subscriptionSubtotal = subscriptionEstimate + (subscriptionEstimate * taxrate);
    const quantityDiscount = cartFromAPI.cart.pricing.bundle_discount_amount/100;

    const licenseTax = (taxrate * cartFromAPI.cart.pricing.final_price)/100;
    const licenseSubtotal = 
        (cartFromAPI.cart.pricing.final_price +
            (taxrate * cartFromAPI.cart.pricing.final_price))/100;

    const findSubscriptionName = (item: OmniStore.SubscriptionCartItem) => {
        const payInterval = item.payInterval === 'MONTHLY' ? '- Monthly' : '- Yearly';
        return `${item.displayName} ${payInterval}`;
    };

    const findLicenseName = (item: OmniStore.LicenseCartItem) => {
        return item.standardOrPro === 'PRO'
            ? item.displayName.includes('Plan')
                ? item.displayName.replace('OmniPlan 4', 'OmniPlan 4 Pro')
                : item.displayName.replace('for Mac', 'Pro for Mac')
            : item.displayName.includes('OmniOutliner 5')
                ? 'OmniOutliner 5 Essentials for Mac'    
                : item.displayName
    };

    const findUpgradeLicenseName = (item: OmniStore.LicenseUpgradeCartItem) => {
        const OOStandardUpgrade = item.shortName === 'omnioutliner' && item.standardOrProUpgrade === 'STANDARD';
        const upgrade_to = productVersion[item.shortName];
        return `${upgrade_to} ${OOStandardUpgrade ? 'Essentials' : item.standardOrProUpgrade} License Upgrade`;
    };

    const noUpgradesInCart = upgradesInCart.length === 0;
    const cartAPIpricing = cartFromAPI.cart.pricing;

    return (
        <TotalCost>
            <Grouping>
                {subscriptionsInCart.length > 0 && (
                    <>
                        <h3>Subscriptions</h3>
                        <ReceiptInformation
                            itemsFromCart={subscriptionsInCart}
                            calculatePricing={(item: OmniStore.SubscriptionCartItem) =>
                                item.payInterval === 'MONTHLY' 
                                    ? (item.monthlyPrice * item.quantity * 0.01).toFixed(2)
                                    : (item.yearlyPrice * item.quantity * 0.01).toFixed(2)
                            }
                            findProductName={findSubscriptionName}
                            taxHeader="Subscription tax: "
                            taxAmount={subscriptionTax}
                            subtotalHeader="Subscription subtotal: "
                            subtotalAmount={subscriptionSubtotal}
                        />
                        <Subscript>
                            Taxes, if applicable, are collected on separate
                            invoices for mixed-timeline subscriptions.
                        </Subscript>
                    </>
                )}
                {(licensesInCart.length > 0 || upgradesInCart.length > 0 )&& (
                    <h3>Licenses</h3>
                )}
                {licensesInCart.length > 0 && (
                    <ReceiptInformation
                        itemsFromCart={licensesInCart}
                        calculatePricing={(item: OmniStore.LicenseCartItem) => 
                            (item.price * item.quantity * 0.01).toFixed(2)
                        }
                        findProductName={findLicenseName}
                        quantityDiscount={noUpgradesInCart ? quantityDiscount: undefined}
                        taxHeader={noUpgradesInCart ? 'License tax:' : undefined}
                        taxAmount={noUpgradesInCart ? licenseTax : undefined}
                        subtotalHeader={noUpgradesInCart ? 'License subtotal:' : undefined}
                        subtotalAmount={noUpgradesInCart ? licenseSubtotal : undefined}
                    />
                )}
                {upgradesInCart.length > 0 && (
                    <ReceiptInformation
                        itemsFromCart={upgradesInCart}
                        calculatePricing={(item: OmniStore.LicenseUpgradeCartItem) => 
                            (item.upgradePrice * item.quantity * 0.01).toFixed(2)
                        }
                        quantityDiscount={quantityDiscount > 0 ? quantityDiscount : undefined}
                        findProductName={findUpgradeLicenseName}
                        taxHeader="License tax:"
                        taxAmount={licenseTax}
                        subtotalHeader="License subtotal:"
                        subtotalAmount={licenseSubtotal}
                    />
                )}
            </Grouping>
            <TotalAppWithTaxCost>
                <ProductName>Total:</ProductName>
                <ProductPrice>
                    <Figures>
                        <FormattedNumber
                            style={`currency`}
                            currency='USD'
                            value={
                                cartAPIpricing 
                                    ? licenseSubtotal + subscriptionSubtotal 
                                    : subscriptionSubtotal
                            }
                        />
                    </Figures>
                </ProductPrice>
            </TotalAppWithTaxCost>
            <Subscript>All totals are in US Dollars.</Subscript>
        </TotalCost>
    )
};

export default Receipt;