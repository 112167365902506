import { CREATE_REFUND_EP } from '../constants/PurchaseEndpoints';

interface PostCreateRefundProps {
    accessToken: string;
    customer_id: string;
    payment_intent_id: string;
    purchaseApiUrl: string;
}
interface CreateRefundResponse {
    amount?: number;
    errors?: Array<{code: number, message: string}>;
    id? : string;
    message?: string;
    status: 'success' | 'error';
}

const postCreateRefund = async ({
    accessToken,
    customer_id,
    payment_intent_id,
    purchaseApiUrl,
}: PostCreateRefundProps): Promise<CreateRefundResponse> => {
    try {
        const createRefund = await fetch(`${purchaseApiUrl}${CREATE_REFUND_EP({
            customer_id: customer_id,
            payment_intent_id,
        })}`,
        {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            mode: 'cors',
        });

        const response = createRefund.json();

        if (response) {
            return response;
        }
    } catch (error) {
        console.log('error creating refund', error);
    }
};

export default postCreateRefund;
