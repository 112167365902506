import { PaymentMethod } from "@stripe/stripe-js"

export enum Type {
    ADD_ACCOUNTS_API_URL = 'ADD_ACCOUNTS_API_URL',
    ADD_CURRENT_CARD = 'ADD_CURRENT_CARD',
    ADD_SUBSCRIPTION_TO_CART = 'ADD_SUBSCRIPTION_TO_CART',
    PUT_CART_FROM_SERVER = 'PUT_CART_FROM_SERVER',
    ADD_LICENSE_TO_CART = 'ADD_LICENSE_TO_CART',
    CLEAR_CART = 'CLEAR_CART',
    TOGGLE_SUBSCRIPTION_PAY_INTERVAL = 'TOGGLE_SUBSCRIPTION_PAY_INTERVAL',
    REMOVE_SUBSCRIPTION = 'REMOVE_SUBSCRIPTION',
    REMOVE_LICENSE = 'REMOVE_LICENSE',
    REMOVE_LICENSE_UPGRADE = 'REMOVE_LICENSE_UPGRADE',
    CONVERT_SUBSCRIPTION_TO_TEAM = 'CONVERT_SUBSCRIPTION_TO_TEAM',
    CONVERT_LICENSE_TO_TEAM = 'CONVERT_LICENSE_TO_TEAM',
    UPDATE_UPGRADE_QUANTITY_IN_CART = 'UPDATE_UPGRADE_QUANTITY_IN_CART',
    ADD_TO_SEAT_LIST = 'ADD_TO_SEAT_LIST',
    ADD_PURCHASE_API_URL = 'ADD_PURCHASE_API_URL',
    REFRESH_ACCESS_TOKEN = 'REFRESH_ACCESS_TOKEN',
    SAVE_CUSTOMER_INFO = 'SAVE_CUSTOMER_INFO',
    SAVE_SUBSCRIPTION_INFO = 'SAVE_SUBSCRIPTION_INFO',
    SET_API_URLS = 'SET_API_URLS',
    SET_EXPANDED_USER_INFO = 'SET_EXPANDED_USER_INFO',
    SET_GLOBAL_ERROR = 'SET_GLOBAL_ERROR',
    SET_MANAGED_TEAM_LICENSES_INFO = 'SET_MANAGED_TEAM_LICENSES_INFO',
    SET_PURCHASES_DATA = 'SET_PURCHASES_DATA',
    SET_STAFF_STATUS = 'SET_STAFF_STATUS',
    SET_STRIPE_PUBLISHABLE_KEY = 'SET_STRIPE_PUBLISHABLE_KEY',
    SET_TEAM_EDIT_ID = 'SET_TEAM_EDIT_ID',
    SIGN_IN_TO_ACCOUNTS = 'SIGN_IN_TO_ACCOUNTS',
    SIGN_OUT_OF_ACCOUNTS = 'SIGN_OUT_OF_ACCOUNTS',
    TOGGLE_SIGNIN_FORM = 'TOGGLE_SIGNIN_FORM',
    ADD_CARDS_FROM_STRIPE = 'ADD_CARDS_FROM_STRIPE',
    ADD_SUBSCRIPTIONS = 'ADD_SUBSCRIPTIONS',
    CLEAR_SUBSCRIPTIONS = 'CLEAR_SUBSCRIPTIONS',
    QUICK_QUANTITY_DISCOUNT = 'QUICK_QUANTITY_DISCOUNT',
    VERIFIED_QUANTITY_DISCOUNT = 'VERIFIED_QUANTITY_DISCOUNT',
    SET_AUTH_TO_FALSE = 'SET_AUTH_TO_FALSE',
    SET_ELIGIBLE_UPGRADES = 'SET_ELIGIBLE_UPGRADES',
}

interface SetStripePublishableKey {
    type: Type.SET_STRIPE_PUBLISHABLE_KEY;
    publishableKey: string;
}
export type StripeAction = SetStripePublishableKey

interface SetInitialSubscriptions {
    type: Type.ADD_SUBSCRIPTIONS;
    subscriptions: Array<OmniStore.Subscription>
}

interface ClearSubscriptions {
    type: Type.CLEAR_SUBSCRIPTIONS;
}

export type SubscriptionAction = 
    | SetInitialSubscriptions
    | ClearSubscriptions

interface ClearCart {
    type: Type.CLEAR_CART;
}

interface PutCartFromAPI {
    type: Type.PUT_CART_FROM_SERVER;
    item: OmniStore.CartFromAPI;
}

interface AddSubscriptionToCart {
    type: Type.ADD_SUBSCRIPTION_TO_CART;
    item: OmniStore.SubscriptionCartItem;
}

interface AddLicenseToCart {
    type: Type.ADD_LICENSE_TO_CART;
    item: OmniStore.LicenseCartItem;
}

interface TogglePayInterval {
    type: Type.TOGGLE_SUBSCRIPTION_PAY_INTERVAL;
    item: OmniStore.SubscriptionCartItem;
}

interface ConvertSubscriptionToTeam {
    type: Type.CONVERT_SUBSCRIPTION_TO_TEAM;
    item: OmniStore.SubscriptionCartItem;
}

interface ConvertLicenseToTeam {
    type: Type.CONVERT_LICENSE_TO_TEAM;
    item: OmniStore.LicenseCartItem;
}

interface UpdateUpgradeQuantityInCart {
    type: Type.UPDATE_UPGRADE_QUANTITY_IN_CART;
    item: OmniStore.LicenseUpgradeCartItem;
}

interface RemoveSubscription {
    type: Type.REMOVE_SUBSCRIPTION;
    item: OmniStore.SubscriptionCartItem;
}

interface RemoveLicense {
    type: Type.REMOVE_LICENSE;
    item: OmniStore.LicenseCartItem;
}

interface RemoveLicenseUpgrarde {
    type: Type.REMOVE_LICENSE_UPGRADE;
    item: OmniStore.LicenseUpgradeCartItem;
}

export type CartAction = 
    | ClearCart
    | PutCartFromAPI
    | AddSubscriptionToCart
    | AddLicenseToCart
    | TogglePayInterval
    | ConvertSubscriptionToTeam
    | ConvertLicenseToTeam
    | UpdateUpgradeQuantityInCart
    | RemoveLicense
    | RemoveLicenseUpgrarde
    | RemoveSubscription

interface SetExpandedUserInfo {
    type: Type.SET_EXPANDED_USER_INFO;
    userinfo: OmniStore.UserInfoExpanded;
}

interface SetAuthToFalse {
    type: Type.SET_AUTH_TO_FALSE;
}

interface SetManagedTeamLicensesInfo {
    type: Type.SET_MANAGED_TEAM_LICENSES_INFO;
    managedTeamLicenses: Array<OmniStore.AccountPurchases>;
}

interface SetPurchaseData {
    type: Type.SET_PURCHASES_DATA;
    purchasesData: Array<OmniStore.AccountPurchases>;
}

interface SignIntoAccounts {
    type: Type.SIGN_IN_TO_ACCOUNTS;
    accountInfo: OmniStore.AccountInfo;
    accountsError: OmniStore.AccountsError;
}

interface SignOutOfAccounts {
    type: Type.SIGN_OUT_OF_ACCOUNTS;
}

interface ToggleSignInForm {
    type: Type.TOGGLE_SIGNIN_FORM;
    display: boolean;
}

interface SetAccountsInformation {
    type: Type.REFRESH_ACCESS_TOKEN;
    accountInfo: OmniStore.AccountInfo;
    accountsError: OmniStore.AccountsError;
}

export type AccountsAction = 
    | SetExpandedUserInfo
    | SetAuthToFalse
    | SetManagedTeamLicensesInfo
    | SetPurchaseData
    | SignIntoAccounts
    | SignOutOfAccounts
    | ToggleSignInForm
    | SetAccountsInformation

// not being used anywhere
interface AddPaymentMethod {
    type: Type.ADD_CURRENT_CARD;
    item: PaymentMethod;
}

interface UpdateQuantityDiscount {
    type: Type.QUICK_QUANTITY_DISCOUNT;
}

interface VerifiedQuantityDiscount {
    type: Type.VERIFIED_QUANTITY_DISCOUNT;
    discount: number;
}

export type PaymentAction = 
    | AddPaymentMethod
    | UpdateQuantityDiscount
    | VerifiedQuantityDiscount

interface EligibleUpgrades {
    type: Type.SET_ELIGIBLE_UPGRADES;
    upgrades: OmniStore.EligibleUpgradesAvailable;
}

export type EligibleUpgradesAction =
    | EligibleUpgrades

export type Action =
    | StripeAction
    | SubscriptionAction
    | CartAction
    | AccountsAction
    | PaymentAction
    | EligibleUpgradesAction
    
