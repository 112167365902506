import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

import { SideBySide } from './Button'
import CartBreadCrumbs from './CartBreadCrumbs';
import CartItemHolder from './CartItemHolder';
import CartSummary from './CartSummaryPricing';
import LicenseCartItemHolder from './LicenseCartItemHolder';
import StoreFrontContainer from './StoreContainer';
import UpgradeCartItemHolder from './UpgradeCartItemHolder';

import { getPurchaseData } from '../utils/getUserData';
import { 
    putCartFromApi, 
    clearCartFromServer,
    removeSubscription, 
    removeLicense,
    removeLicenseUpgrade
} from '../actions/cart';
import { postCart } from '../utils/postCart';
import { checkCartUpgrades } from '../utils/handleCartCheck';
import { SSKey, sessionStorageService } from '../utils/handleSessionStorage';
import { createUpgradeJSON, createLicenseJSON} from '../utils/createPaymentIntentJson';

import * as vars from '../styles/variables';
import getEligibleUpgrades from '../utils/getUpgradeItems';

interface StyledProps {
    simple?: boolean;
}

const SectionHeader = styled.h1`
    font-style: italic;
    font-size: 26px;
    font-weight: 600;
    margin: 30px 0 0;

    &:first-of-type {
        margin-top: 10px;
    }
`;
const CartContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: ${vars.whiteColor};

    h2 {
        color: ${vars.whiteColor};
        padding-bottom: 8px;
        font-size: 28px;

    }

    a {
        color: ${vars.whiteColor};
    }

    @media (max-width: 900px) {
        width: 100%;
        margin: 20px auto;
        max-width: unset;
    }
`;

const CartContents = styled.div<StyledProps>`
    text-align: ${(props): string => (props.simple ? 'center' : 'unset')};
    margin: auto 0;
    width: 60%;
    padding-right: 40px;
    display: ${(props): string => (props.simple ? 'none' : 'block')};

    @media (max-width: 900px) {
        margin: auto;
        max-width: unset;
        padding-right: unset;
        width: 100%;
    }

    div:last-child {
        border-bottom: 0;
    }
`;

const CartContentContainer = styled.div`
    border: 1px solid white;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
`;

interface CartProps {
    simple?: boolean;
}

const Cart: React.FC<CartProps> = (props) => {
    const dispatch = useDispatch();
    const { trackEvent } = useMatomo();

    const accountsApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.accountsAPIURL);
    const purchaseApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.purchaseAPIURL);
    const purchasesData = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.purchasesData);
    const accessToken = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.accessToken);
    const hasAuth = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.has_auth);
    const signedInStatus = useSelector((state: OmniStore.GlobalReduxState) => ({account: state.accounts.expandedUserInfo}));

    const [cartFromAPI, setCartFromAPI] = React.useState<OmniStore.CartFromAPI>({
        cart: null,
        pricing: {
            bundle_discount_amount: 0,
            bundle_discount_percent: 0,
            cart_total: 0,
            final_price: 0,
            upgrade_discount: 0,
        },
    });

    const [duplicateSubscription, setDuplicateSubscription] = React.useState<boolean>(false);
    const [emptyCart, setEmptyCart] = React.useState<boolean>(true);

    const previousCartRef = React.useRef(0);
    const previousCartUpgradesRef = React.useRef(0);

    const [cartError, setCartError] = React.useState<boolean>(false);
    const [errorText, setErrorText] = React.useState<null | string>(null);

    const [loading, setLoading] = React.useState<boolean>(true);

    const eligibleUpgrades = useSelector((state: OmniStore.GlobalReduxState) => ({upgrades: state.upgrades}));
    const cartItems = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.cart}));
    const subscriptionsInCart = cartItems.cart.subscriptions;
    const licensesInCart = cartItems.cart.licenses;
    const upgradesInCart = cartItems.cart.upgrades;

    //grab most updated purchase data every min
    React.useEffect(() => {
        const interval = setInterval(() => {
            getEligibleUpgrades({accessToken, purchaseApiUrl});
            getPurchaseData(accessToken, accountsApiUrl);
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    //double check cart subcriptions aren't already purchased after updated data
    React.useEffect(() => {
        if (purchasesData) {
            subscriptionsInCart.map((item) => {
                purchasesData.map((product) => {
                    if (product.vending_product_id[0] === item.blockableVendingIds[0]) {
                        dispatch(
                            removeSubscription({item})
                        );
                        trackEvent({
                            action: 'Cart Change',
                            category: 'Ecommerce',
                        });
                        setDuplicateSubscription(true);
                    }
                });
            });
            setLoading(false);
        }
    }, [ 
        hasAuth,
        purchasesData, 
    ]);

    React.useEffect(() => {
        const subscriptionsInCartAmount = subscriptionsInCart.length;
        const licensesInCartAmount = licensesInCart.length;
        const upgradesInCartAmount = upgradesInCart.length;

        if (
            subscriptionsInCartAmount === 0 && 
            licensesInCartAmount === 0 && 
            upgradesInCartAmount === 0
        ) {
            setEmptyCart(true);
        } else {
            setEmptyCart(false);
        }
    }, [cartItems]);

    React.useEffect(() => {
        setLoading(true);
    }, [
        subscriptionsInCart,
        licensesInCart,
        upgradesInCart
    ]);

    //remove setupIntent at every refresh so that payment info is not being saved 
    React.useEffect(() => {
        if (sessionStorageService.get(SSKey.SetupIntentClientSecret)) {
            sessionStorageService.remove(SSKey.SetupIntentClientSecret);
            sessionStorageService.remove(SSKey.SetupIntentID);
        }
    }, [subscriptionsInCart]);

    //remove paymentIntent at every refresh so that payment info is not being saved 
    React.useEffect(() => {
        if (licensesInCart.length === 0) {
            sessionStorageService.remove(SSKey.PaymentIntentID);
            sessionStorageService.remove(SSKey.PaymentIntentClientSecret);
            dispatch(clearCartFromServer());
        }
    }, [licensesInCart, upgradesInCart]);

    //posts items to cart every time we receive new data, will remove item from cart if unavailable
    React.useEffect(() => {
        if (loading && (licensesInCart.length > 0 || upgradesInCart.length > 0)) {
            //set the previousCartRef to whatever the length of the license shopping cart is.
            // if it drops to 0 at any time, let's remove
            previousCartRef.current = licensesInCart.length;
            previousCartUpgradesRef.current = upgradesInCart.length;

            const licensesJson = createLicenseJSON(licensesInCart);
            const upgradeJson = createUpgradeJSON(upgradesInCart);

            postCart({
                accessToken: accessToken,
                cart: {
                    new_purchases: licensesJson,
                    upgrades: upgradeJson,
                },
                purchaseApiUrl: purchaseApiUrl,
                tax_rate: 0,
            })
                .then((result) => {
                    const errorCode = result.errorCode;

                    if (errorCode) {
                        let eligibleItems;
                        switch(errorCode) {
                            case 5014: 
                                const product_id = result.message.split(': ')[2];
                                cartItems.cart.licenses.map((item) => {
                                    if (item.productID === product_id) {
                                        setTimeout(() => {
                                            setCartError(true);
                                            setErrorText('One or more items in your cart has been removed because it is no longer in sale.');
                                            dispatch(removeLicense({item}));
                                            trackEvent({
                                                action: 'Cart Change',
                                                category: 'Ecommerce',
                                            });
                                        }, 1000)
                                    }
                                });
                                return;
                            //error verifying upgrades in cart
                            case 5016:
                                eligibleItems = checkCartUpgrades({
                                    cartUpgrades: upgradesInCart,
                                    eligibleUpgrades: eligibleUpgrades.upgrades,
                                    returnIneligibleItem: true
                                }) as {item?: OmniStore.LicenseUpgradeCartItem, status: boolean};

                                if (eligibleItems.status === false) {
                                    setTimeout(() => {
                                        setCartError(true);
                                        setErrorText('One or more items in your cart has been removed because it is no longer in sale.');
                                        dispatch(removeLicenseUpgrade({
                                            item: {...eligibleItems.item}
                                        }));
                                        trackEvent({
                                            action: 'Cart Change',
                                            category: 'Ecommerce',
                                        });                                   
                                    }, 2000);
                                }
                                return;
                            case 5017:
                                eligibleItems = checkCartUpgrades({
                                    cartUpgrades: upgradesInCart,
                                    eligibleUpgrades: eligibleUpgrades.upgrades,
                                    returnIneligibleItem: true
                                }) as {item?: OmniStore.LicenseUpgradeCartItem, status: boolean};
                        
                                if (eligibleItems.status === false) {
                                    setTimeout(() => {
                                        setCartError(true);
                                        setErrorText('One or more items in your cart has been removed because it is no longer in sale.');
                                        dispatch(removeLicenseUpgrade({
                                            item: {...eligibleItems.item}
                                        }));
                                        trackEvent({
                                            action: 'Cart Change',
                                            category: 'Ecommerce',
                                        });                                   
                                    }, 2000);
                                }
                                return;
                            default:
                                setCartError(true);
                                setErrorText(
                                'There was an error connecting to the store backend. Please try reloading the page. If this error persists please contact us at sales@omnigroup.com.'
                                );
                                return;
                        }
                    } else {
                        setCartFromAPI(result as OmniStore.CartFromAPI);
                        dispatch(putCartFromApi({ item: result }));
                    }
                })
                .catch((error) => {
                    console.log('post cart error: ', error);
                    setCartError(true);
                    setErrorText(
                        'There was an error connecting to the store backend. Please try reloading the page. If this error persists please contact us at sales@omnigroup.com.'
                    );
                });
            setLoading(false);
        }
    }, [
        cartItems, 
        signedInStatus, 
        purchasesData,
    ]);

    if (emptyCart) {
        return (
            <StoreFrontContainer style={{ marginTop: '20px' }}>
                <CartContainer>
                    <CartBreadCrumbs page={props.simple ? null : 'CART'} />
                    <h2>Empty Cart!</h2>
                    {cartError && (
                        <div>
                            <h2>Uh oh!</h2>
                            <p>{errorText}</p>
                        </div>
                    )}
                    {duplicateSubscription && (
                        <div>
                            <p>
                                Duplicate subscriptions have been removed from your cart. 
                                To add more seats, please use the Team Management interface in&nbsp;
                                <a href="https://accounts.omnigroup.com">Omni Account.</a>
                            </p>
                        </div>
                    )}
                    <p>
                        Your cart is currently empty. Head back to the{' '}
                        <a href='/'>storefront</a> to add items to your cart.
                    </p>
                </CartContainer>
            </StoreFrontContainer>
        )
    } else {
        return (
            <StoreFrontContainer>
                <CartContainer>
                    <CartBreadCrumbs page={props.simple ? null : 'CART'} />
                    {!props.simple && (
                        <h2>
                            <FormattedMessage id='shoppingCart' />
                        </h2>
                    )}
                    {cartError && (
                        <div>
                            <h2>Uh oh!</h2>
                            <p>{errorText}</p>
                        </div>
                    )}
                    <CartContentContainer>
                        <SideBySide flexStart>
                            <CartContents simple={props.simple}>
                                {subscriptionsInCart.length >=1 && (
                                    <>
                                        <SectionHeader>
                                            <FormattedMessage id='Subscriptions'/>
                                        </SectionHeader>
                                        {subscriptionsInCart.map((item) => (
                                            <CartItemHolder
                                                key={item.dateAddedToCart}
                                                item={item}
                                                simple={props.simple ? true : false}
                                            />
                                        ))};
                                    </>
                                )};
                                {licensesInCart.length >= 1 && (
                                    <>
                                        <SectionHeader>
                                            {" "}
                                            <FormattedMessage id="TraditionalLicenses"/>
                                        </SectionHeader>
                                        {licensesInCart.map((item, key) => (
                                            <LicenseCartItemHolder
                                                key={key}
                                                item={item}
                                                simple={props.simple ? true : false}
                                            />
                                        ))};
                                    </>
                                )};
                                {upgradesInCart.length >= 1 && (
                                    <>
                                        <SectionHeader>
                                            {" "}
                                            <FormattedMessage id="LicenseUpgrades"/>
                                        </SectionHeader>
                                        {upgradesInCart.map((item, key) => (
                                            <UpgradeCartItemHolder
                                                key={key}
                                                item={item}
                                                simple={props.simple ? true : false}
                                            />
                                        ))};
                                    </>
                                )};
                            </CartContents>
                            {!props.simple && 
                                (cartFromAPI.pricing !== undefined || 'message' in cartFromAPI) && (
                                    <CartSummary cartSummaryFigures={cartFromAPI} />
                                )
                            }
                        </SideBySide>
                    </CartContentContainer>
                </CartContainer>
            </StoreFrontContainer>
        )
    }
}

export default Cart;
