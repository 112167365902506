enum LSKey {
    Cart = 'cart',
    PaymentIntentClientSecret = 'paymentIntentClientSecret',
    PaymentIntentID = 'paymentIntentID',
    StoreAuth = 'storeauth',
}

interface LSModel {
    [LSKey.Cart]: OmniStore.GlobalCartState["cart"];
    [LSKey.PaymentIntentClientSecret]: string;
    [LSKey.PaymentIntentID]: string;
    [LSKey.StoreAuth]: OmniStore.AuthInfo;
}

interface iLocalStorage<T> {
    get<K extends keyof T>(key: K): T[K] | null;
    set<K extends keyof T>(key: K, value: T[K]): void;
    remove<K extends keyof T>(key: K): void;
    clear(): void;
}

class LocalStorageService<T> implements iLocalStorage<T> {
    constructor(private readonly storage: Storage) {}

    set<K extends keyof T>(key: K, value: T[K]): void {
        this.storage.setItem(key.toString(), JSON.stringify(value));
    }
    
    get<K extends keyof T>(key: K): T[K] | null {
        const value = this.storage.getItem(key.toString());

        if (value === null || value === 'null' || value === undefined || value === 'undefined') {
            return null;
        }

        return JSON.parse(value);
    }

    remove<K extends keyof T>(key: K): void {
        this.storage.removeItem(key.toString());
    }

    clear(): void {
        this.storage.clear();
    }
}

const localStorageService = new LocalStorageService<LSModel>(
    window.localStorage
);


export {
    LSKey,
    localStorageService
};
