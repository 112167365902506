import { GET_PAYMENT_INTENT_EP } from '../constants/PurchaseEndpoints';

interface GetPaymentIntentProps {
    accessToken: string;
    customer_id: string;
    payment_intent_id: string;
    purchaseApiUrl: string;
}

interface GetPaymentIntentResponse {
    paymentIntentInfo?: any;
    error?: Array<{code: number, message: string}>;
}

const getPaymentIntent = async ({
    accessToken,
    customer_id,
    payment_intent_id,
    purchaseApiUrl,
}: GetPaymentIntentProps): Promise<GetPaymentIntentResponse> => {
    try {
        const paymentIntent = await fetch(`${purchaseApiUrl}${GET_PAYMENT_INTENT_EP({
            customer_id,
            payment_intent_id
        })}`, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            method: 'GET',
            mode: 'cors',
        });

        const response = await paymentIntent.json();

        if (response) {
            if (response.errors) {
                return {error: response.errors}
            }
            return {paymentIntentInfo: response};
        }
    } catch (error) {
        console.log('getPaymentIntent error: ', error)
    }
}

export default getPaymentIntent;
