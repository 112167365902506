export const createUpgradeJSON = (upgradesInCart: Array<OmniStore.LicenseUpgradeCartItem>) => {
    return upgradesInCart.reduce((accrued, item) => {
        const upgradeItem = {
            registration_identifier: item.registration_identifier,
            upgrade_quantity: item.quantity
        };

        if (!accrued[item.upgradeTo]) {
            accrued[item.upgradeTo] = [];
        }
        accrued[item.upgradeTo].push(upgradeItem);
        return {...accrued}
    }, {});
};

export const createLicenseJSON = (licensesInCart: Array<OmniStore.LicenseCartItem>) => {
    return licensesInCart.map((item) => {
        if (item.standardOrPro === 'PRO') {
            return {
                product_id: item.productID,
                quantity: item.quantity,
            };
        }
        return {
            product_id: item.productID.replace('_PRO', '_STD'), //delete _PRO if not PRO
            quantity: item.quantity,
        };
    });
};
