import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage, FormattedNumber } from 'react-intl';

const ProductPrice = styled.div`
    width: 30%;
    font-size: 14px;
    text-align: right;
`;

const ProductName = styled.div`
    font-size: 16px;
    font-weight: 600;
    width: 70%;
`;

const ProductRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    width: 100%;
`;

const Figures = styled.span`
    flex-grow: 1;
    min-width: 100px;
`;

const QuantityRow = styled.span`
    width: 100%;
    font-size: 14px;
`;

const ReceiptItem = styled.div`
    width: 100%;
    margin: 5px 0 15px;
`;

interface ReceiptInfoProps {
    itemsFromCart: Array<OmniStore.SubscriptionCartItem | OmniStore.LicenseCartItem | OmniStore.LicenseUpgradeCartItem>;
    quantityDiscount?: number;
    subtotalHeader?: string;
    subtotalAmount?: number;
    taxHeader?: string;
    taxAmount?: number;
    calculatePricing: (item: OmniStore.SubscriptionCartItem | OmniStore.LicenseCartItem | OmniStore.LicenseUpgradeCartItem) => string;  
    findProductName: (item: OmniStore.SubscriptionCartItem | OmniStore.LicenseCartItem | OmniStore.LicenseUpgradeCartItem) => string;
}

const ReceiptInformation: React.FC<ReceiptInfoProps> = (props) => {
    const { 
        itemsFromCart,
        quantityDiscount,
        subtotalAmount,
        subtotalHeader,
        taxAmount,
        taxHeader,
        calculatePricing,
        findProductName,
    } = props;

    return (
        <>
            {itemsFromCart.map((item) => {
                const pricing = calculatePricing(item);
                const name = findProductName(item);

                return (
                    <ReceiptItem key={item.displayName}>
                        <ProductRow>
                            <ProductName>{name}</ProductName>
                            <ProductPrice>${pricing}</ProductPrice>
                        </ProductRow>
                        <QuantityRow>
                            <FormattedMessage id='Quantity' />:{' '}{item.quantity}
                        </QuantityRow>
                    </ReceiptItem>
                )
            })}
            {taxHeader && subtotalHeader && (
                <>
                    {quantityDiscount ? (
                        <ProductRow>
                            <ProductName>Quantity discount:</ProductName>
                            <ProductPrice>
                                <Figures>
                                    <FormattedNumber
                                        style={`currency`}
                                        currency='USD'
                                        value={-quantityDiscount}
                                    />
                                </Figures>
                            </ProductPrice>
                        </ProductRow>
                    ) : null}
                    <ProductRow>
                        <ProductName>{taxHeader}</ProductName>
                        <ProductPrice>
                            <Figures>
                                <FormattedNumber
                                    currency="USD"
                                    style={'currency'}
                                    value={taxAmount}
                                />
                            </Figures>
                        </ProductPrice>
                    </ProductRow>
                    <ProductRow>
                        <ProductName>{subtotalHeader}</ProductName>
                        <ProductPrice>
                            <Figures>
                                <FormattedNumber
                                    currency='USD'
                                    style={`currency`}
                                    value={subtotalAmount}
                                />
                            </Figures>
                        </ProductPrice>
                    </ProductRow>
                </>
            )}
        </>
    )
};

export default ReceiptInformation;
