import { ActionType, Action } from '../actions/types';
import { LSKey, localStorageService } from '../utils/handleLocalStorage';

const initialCartState: OmniStore.GlobalCartState = {
    cart: { 
        licenses: [],
        subscriptions: [],
        upgrades: [],
    },
    hideCart: false,
    serverCart: {
        cart: null,
        pricing: {
            bundle_discount_amount: 0,
            bundle_discount_percent: 0,
            cart_total: 0,
            final_price: 0,
            upgrade_discount: 0,
        },
    },
};

const cart = (state = initialCartState, action: Action): OmniStore.GlobalCartState => {
    switch (action.type) {
        case ActionType.CLEAR_CART: {
            const newLicenses: Array<OmniStore.LicenseCartItem> = [];
            const newSubscriptions: Array<OmniStore.SubscriptionCartItem> = [];
            const newUpgrades: Array<OmniStore.LicenseUpgradeCartItem> = [];

            localStorageService.remove(LSKey.Cart);

            return {
                ...state,
                cart: {
                    licenses: newLicenses,
                    subscriptions: newSubscriptions,
                    upgrades: newUpgrades,
                },
                hideCart: false,
            };
        }
        case ActionType.PUT_CART_FROM_SERVER: {
            return {
                ...state,
                serverCart: action.item,
            };
        }
        case ActionType.CLEAR_CART_FROM_SERVER: {
            return {
                ...state,
                serverCart: {
                    cart: null,
                    pricing: {
                        bundle_discount_amount: 0,
                        bundle_discount_percent: 0,
                        cart_total: 0,
                        final_price: 0,
                        upgrade_discount: 0,
                    }
                }
            };
        }
        case ActionType.ADD_SUBSCRIPTION_TO_CART: {
            const newCart = state.cart;

            if (newCart.subscriptions.length === 0) {
                newCart.subscriptions.push(action.item);
            } else {
                newCart.subscriptions = newCart.subscriptions.filter(
                    (value) => value.displayName !== action.item.displayName
                );
                newCart.subscriptions.push(action.item);
            }
            if (newCart.subscriptions.length > 0) {
                localStorageService.set(LSKey.Cart, newCart);
            }

            return {
                ...state,
                cart: newCart,
            };
        }
        case ActionType.ADD_LICENSE_TO_CART: {
            const newCart = state.cart;
            newCart.licenses.push(action.item);
            if (newCart.licenses.length > 0) {
                localStorageService.set(LSKey.Cart, newCart);
            }
            return {
                ...state,
                cart: newCart,
            };
        }
        case ActionType.ADD_LICENSE_UPGRADE_TO_CART: {
            const newCart = state.cart;
            newCart.upgrades.push(action.item);

            if (newCart.upgrades.length > 0) {
                localStorageService.set(LSKey.Cart, newCart);
            }
            return {
                ...state,
                cart: newCart
            }
        }
        case ActionType.TOGGLE_SUBSCRIPTION_PAY_INTERVAL: {
            const newCart = state.cart;
            const brandNewCart = newCart.subscriptions.map((value: OmniStore.SubscriptionCartItem) => {
                    if (value.dateAddedToCart === action.item.dateAddedToCart) {
                        return {
                            ...value,
                            payInterval: action.item.payInterval,
                        };
                    }
                    return value;
                }
            );
            if (brandNewCart.length > 0) {
                newCart.subscriptions = brandNewCart;
                localStorageService.set(LSKey.Cart, newCart);
            }
            return {
                ...state,
                cart: newCart,
            };
        }
        case ActionType.CONVERT_SUBSCRIPTION_TO_TEAM: {
            const newCart = state.cart;
            const brandNewCart = newCart.subscriptions.map(
                (value: OmniStore.SubscriptionCartItem) => {
                    if (value.dateAddedToCart === action.item.dateAddedToCart) {
                        return {
                            ...value,
                            quantity: action.item.quantity,
                        };
                    }
                    return value;
                }
            );
            if (brandNewCart.length > 0) {
                newCart.subscriptions = brandNewCart;
                localStorageService.set(LSKey.Cart, newCart);
            }
            return {
                ...state,
                cart: newCart,
            };
        }
        case ActionType.CONVERT_LICENSE_TO_TEAM: {
            const newCart = state.cart;
            const brandNewCart = newCart.licenses.map(
                (value: OmniStore.LicenseCartItem) => {
                    if (value.dateAddedToCart === action.item.dateAddedToCart) {
                        return {
                            ...value,
                            quantity: action.item.quantity,
                        };
                    }
                    return value;
                }
            );
            if (brandNewCart.length > 0) {
                newCart.licenses = brandNewCart;
                localStorageService.set(LSKey.Cart, newCart);
            }
            return {
                ...state,
                cart: newCart,
            };
        }
        case ActionType.UPDATE_UPGRADE_QUANTITY_IN_CART: {
            const newCart = state.cart;
            const brandNewCart = newCart.upgrades.map(
                (value: OmniStore.LicenseUpgradeCartItem) => {
                    if (value.dateAddedToCart === action.item.dateAddedToCart) {
                        return {
                            ...value,
                            quantity: action.item.quantity,
                        };
                    }
                    return value;
                }
            );
            if (brandNewCart.length > 0) {
                newCart.upgrades = brandNewCart;
                localStorageService.set(LSKey.Cart, newCart);
            }
            return {
                ...state,
                cart: newCart,
            };
        }
        case ActionType.REMOVE_SUBSCRIPTION: {
            const newCart = state.cart;
            const brandNewCart = newCart.subscriptions.filter(
                (value: OmniStore.SubscriptionCartItem) =>
                    value.dateAddedToCart !== action.item.dateAddedToCart
            );
            newCart.subscriptions = brandNewCart;
            localStorageService.remove(LSKey.Cart);
            localStorageService.set(LSKey.Cart, newCart);
            return {
                ...state,
                cart: newCart,
            };
        }
        case ActionType.REMOVE_LICENSE: {
            const newCart = state.cart;
            const brandNewCart = newCart.licenses.filter(
                (value: OmniStore.LicenseCartItem) =>
                    value.dateAddedToCart !== action.item.dateAddedToCart
            );
            newCart.licenses = brandNewCart;
            localStorageService.remove(LSKey.Cart);
            localStorageService.set(LSKey.Cart, newCart);
            return {
                ...state,
                cart: newCart,
            };
        }
        case ActionType.REMOVE_LICENSE_UPGRADE: {
            const newCart = state.cart;
            const brandNewCart = newCart.upgrades.filter(
                (value: OmniStore.LicenseUpgradeCartItem) =>
                    value.dateAddedToCart !== action.item.dateAddedToCart
            );
            newCart.upgrades = brandNewCart;
            localStorageService.remove(LSKey.Cart);
            localStorageService.set(LSKey.Cart, newCart);
            return {
                ...state,
                cart: newCart,
            };
        }
        default:
            return state;
    }
};

export default cart;
