enum SSKey {
    PaymentIntentClientSecret = 'paymentIntentClientSecret',
    PaymentIntentID = 'paymentIntentID',
    SetupIntentClientSecret = 'setupIntentClientSecret',
    SetupIntentID = 'setupIntentID',
}

interface SSModel {
    [SSKey.PaymentIntentClientSecret]: string;
    [SSKey.PaymentIntentID]: string;
    [SSKey.SetupIntentClientSecret]: string;
    [SSKey.SetupIntentID]: string;
}

interface iSessionStorage<T> {
    get<K extends keyof T>(key: K): T[K] | null;
    set<K extends keyof T>(key: K, value: T[K]): void;
    remove<K extends keyof T>(key: K): void;
    clear(): void;
}

class SessionStorageService<T> implements iSessionStorage<T> {
    constructor(private readonly storage: Storage) {}

    set<K extends keyof T>(key: K, value: T[K]): void {
        this.storage.setItem(key.toString(), JSON.stringify(value));
    }
    
    get<K extends keyof T>(key: K): T[K] | null {
        const value = this.storage.getItem(key.toString());

        if (value === null || value === 'null' || value === undefined || value === 'undefined') {
            return null;
        }
        return JSON.parse(value);
    }

    remove<K extends keyof T>(key: K): void {
        this.storage.removeItem(key.toString());
    }

    clear(): void {
        this.storage.clear();
    }
}

const sessionStorageService = new SessionStorageService<SSModel>(
    window.sessionStorage
);


export {
    SSKey,
    sessionStorageService
};
