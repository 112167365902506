import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'react-content-loader';
import { setUser } from '@sentry/react';

import BillingDetails from './BillingDetails';
import CardInformation from './CardInformation';
import Success from './Success';

import { clearCart } from '../../actions/cart';
import { createUpgradeJSON, createLicenseJSON} from '../../utils/createPaymentIntentJson';
import getPaymentIntent from '../../utils/getPaymenIntent';
import getSetupIntent from '../../utils/getSetupIntent';
import { postPaymentIntent } from '../../utils/postPaymentIntent';
import { postSetupIntent } from '../../utils/postSetupIntent';
import postCreateCustomer from '../../utils/postCreateCustomer';
import { getUserInfo } from '../../utils/getUserData';

import { postUpdatePaymentIntent } from '../../utils/postUpdatePaymentIntent';
import { SSKey, sessionStorageService } from '../../utils/handleSessionStorage';
import { LSKey, localStorageService } from '../../utils/handleLocalStorage';


interface BillingFormProps {
    showApplePay?: boolean;
}

const BillingInformation: React.FC<BillingFormProps> = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [taxRateIfApplicable, setTaxRate] = React.useState<OmniStore.TaxRateInformation>({
        jurisdiction: undefined,
        tax_rate: '0',
    });
    const [customerInformation, setCustomerInformation] = React.useState<null | {[key:string]: string}>(null);

    const accountsApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.accountsAPIURL);
    const purchaseApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.purchaseAPIURL);
    const accessToken = useSelector((state: OmniStore.GlobalReduxState) => state.accounts.accessToken);
    const signedInAccount = useSelector((state: OmniStore.GlobalReduxState) => ({ account: state.accounts.expandedUserInfo,}));

    const [customerID, setCustomerID] = React.useState<null | string>(null);

    const [setupIntentID, setSetupIntentID] = React.useState<null | string>(null);
    const [setupIntentCS, setSetupIntentCS] = React.useState<null | string>(null);
    const [setupIntentError, setSetupIntentError] = React.useState<boolean>(false);

    const [paymentIntentID, setPaymentIntentID] = React.useState<null | string>(null);
    const [paymentIntentCS, setPaymentIntentCS] = React.useState<null | string>(null);
    const [paymentIntentError, setPaymentIntentError] = React.useState<boolean>(false);

    const [errorText, setErrorText] = React.useState<null | string>(null);
    const [success, setSuccess] = React.useState<{
        licenses: boolean,
        subscriptions: boolean,
        upgrades: boolean,
    }>({
        licenses: false,
        subscriptions: false,
        upgrades: false,
    });
    const [successCart, setSuccessCart] = React.useState<{
        licenses?: Array<{displayName: string; standardOrPro: string}>;
        subscriptions?: Array<string>;
        upgrades?: Array<{displayName: string, standardOrProUpgrade: string}>;
    }>({});
    const [showSuccessPage, setShowSuccessPage] = React.useState<boolean>(false);

    const cartFromAPI = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.serverCart,}))
    const cartItems = useSelector((state: OmniStore.GlobalReduxState) => ({cart: state.cart.cart }));
    const licensesInCart = cartItems.cart.licenses;
    const licensesInCartAmount = licensesInCart.length;

    const upgradesInCart = cartItems.cart.upgrades;
    const upgradesInCartAmount = upgradesInCart.length;

    const subscriptionsInCart = cartItems.cart.subscriptions;
    const subscriptionsInCartAmount = cartItems.cart.subscriptions.length;

    const [counter, setCounter] = React.useState<number>(0);
    const timer = React.useRef(null);
    const currentCount = 1;
    const [loading, setLoading] = React.useState<boolean>(false);

    const storagePaymentIntentID = sessionStorageService.get(SSKey.PaymentIntentID);
    const storageSetupIntentID = sessionStorageService.get(SSKey.SetupIntentID);

    React.useEffect(() => {
        if (licensesInCartAmount === 0) {
            setSuccess((prevState) => ({...prevState, licenses: true}));
        }
        if (subscriptionsInCartAmount === 0) {
            setSuccess((prevState) => ({...prevState, subscriptions: true}));
        }
        if (upgradesInCartAmount === 0) {
            setSuccess((prevState) => ({...prevState, upgrades: true}));
        }
    }, [])

    //grab customerID
    React.useEffect(() => {
        if (!signedInAccount) {
            return;
        }
        //if customerID doesn't exist, create one
        if (!customerID) {
            if (signedInAccount.account.customer_id === undefined) {
                postCreateCustomer({
                    accessToken,
                    email: signedInAccount.account.email,
                    omni_id: signedInAccount.account.omni_id,
                    omni_username: signedInAccount.account.username,
                    purchaseApiUrl,
                })
                    .then((response) => {
                        setCustomerID(response.customer_id)
                        getUserInfo(accessToken, accountsApiUrl);
                    })
                    .catch((error) => console.log('cannot create new customerID: ', error))
            }
        }
        setLoading(false)
        setUser({
            username: signedInAccount.account 
                ? signedInAccount.account.username
                : undefined,
        }); 
    }, [signedInAccount]);

    React.useEffect(() => {
        if (signedInAccount && signedInAccount.account.customer_id) {
            setCustomerID(signedInAccount.account?.customer_id[0]);
        }
    }, [signedInAccount])

    // delete setupIntent every 10 min = 600,000 then navigate back to cart
    React.useEffect(() => {
        //currentCount increases every minute
        timer.current = setInterval(() => setCounter((sec) => sec + 1), currentCount * 60000);
        if (counter > 3) {
            setLoading(true);
            sessionStorageService.remove(SSKey.SetupIntentID);
            sessionStorageService.remove(SSKey.SetupIntentClientSecret);

            setTimeout(() => {
                navigate('/cart');
            }, 3000);
        }
        return () => clearInterval(timer.current);
    }, [counter]);

    // create setupIntent for subscription purchases
    React.useEffect(() => {
        if (customerID && subscriptionsInCart.length > 0) {
            if (storageSetupIntentID !== null) {
                getSetupIntent({
                    accessToken,
                    customer_id: customerID,
                    purchaseApiUrl,
                    setup_intent_id: storageSetupIntentID, 
                })
                    .then((response) => {
                        setSetupIntentID(response.setupIntentInfo.id);
                        setSetupIntentCS(response.setupIntentInfo.client_secret);
                    })
                    .catch((error) => console.log('unable to get setup intent: ', error));
            } else {
                postSetupIntent({
                    accessToken,
                    customer_id: customerID,
                    purchaseApiUrl,
                    usage: 'off_session',
                })
                    .then((result) => {
                        if (result.errors) {
                            setSetupIntentError(true);
                            setErrorText('There was an error, please contact sales support');
                        } else {
                            setSetupIntentCS(result.success.setupIntentClientSecret);
                            setSetupIntentID(result.success.setupIntentID);
                        }
                    })
                    .catch((error) => console.log('unable to setupIntent: ', error))
            }
        }
    }, [customerID, subscriptionsInCart]);

    const compareServerCartInfo = (serverCartMetadata) => {
        const serverCart = {};
        let updateCart = false;
        //grab all items in serverCart
        for (const key in serverCartMetadata) {
            if (key.includes('purchase')) {
                const item = JSON.parse(serverCartMetadata[key]);
                serverCart[item.product_id] = item;
            }
        }

        const upgrades = upgradesInCart.map((item) => ({
            dateAddedToCart: item.dateAddedToCart,
            displayName: item.displayName,
            price: item.upgradePrice,
            productID: item.upgradeTo,
            quantity: item.quantity,
        }));
        const currentCart = [ ...licensesInCart, ...upgrades];
        const currentCartJSON = currentCart.reduce((accrued, item) => {
            accrued[item.productID] = item.quantity;
            return {...accrued}
        }, {});

        for (const key in currentCartJSON) {
            if (!serverCart[key]) {
                updateCart = true;
                return updateCart;
            }
            if (serverCart[key].quantity !== currentCartJSON[key].quantity) {
                updateCart = true;
                return updateCart;
            }
        }
        return updateCart;
    }

    React.useEffect(() => {
        if (paymentIntentError) {
            // delete paymentIntent ID and CS (maybe already checked out with another browser)
            sessionStorageService.remove(SSKey.PaymentIntentID);
            sessionStorageService.remove(SSKey.PaymentIntentClientSecret);

            //if final pricing is 0, clear cart and navigate back to cart
            if (cartFromAPI.cart.pricing.final_price === 0) {
                localStorageService.remove(LSKey.Cart);
                dispatch(clearCart());
                navigate('/cart');
            }

            const licensesJSON = createLicenseJSON(licensesInCart);
            const upgradesJSON = createUpgradeJSON(upgradesInCart);

            postPaymentIntent({
                accessToken,
                cart: {
                    new_purchases: licensesJSON,
                    upgrades: upgradesJSON,
                },
                customer_id: customerID,
                initialAmount: cartFromAPI.cart.pricing.final_price,
                purchaseApiUrl
            })
                .then((response) => {
                    if (response.errors) {
                        setLoading(true);
                        setPaymentIntentError(true);
                        setErrorText('There was an problem on the backend, please wait 5 seconds and try again')
                    } else {
                        setPaymentIntentID(response.id)
                        setPaymentIntentCS(response.cs)
                    }
                })
                .catch((error) => console.log('unable to create payment intent: ', error))
        }
        setPaymentIntentError(false);
    }, [paymentIntentError])

    //create paymentIntent for license purchases
    React.useEffect(() => {
        if (customerID && (licensesInCartAmount > 0 || upgradesInCartAmount > 0)) {
            const licensesJSON = createLicenseJSON(licensesInCart);
            const upgradesJSON = createUpgradeJSON(upgradesInCart);
            //if paymentIntent exists, get intent
            if (storagePaymentIntentID !== null) {
                getPaymentIntent({
                    accessToken,
                    customer_id: customerID,
                    payment_intent_id: storagePaymentIntentID,
                    purchaseApiUrl,
                })
                    .then((response) => {
                        setPaymentIntentID(response.paymentIntentInfo.id);
                        setPaymentIntentCS(response.paymentIntentInfo.client_secret);

                        const cartMatch = compareServerCartInfo(response.paymentIntentInfo.metadata);
                        if (cartMatch === false) {
                            postUpdatePaymentIntent({
                                accessToken,
                                cart: {
                                    new_purchases: licensesJSON,
                                    upgrades: upgradesJSON,
                                },
                                customer_id: customerID,
                                paymentIntentID: response.paymentIntentInfo.id,
                                purchaseApiUrl,
                                updatedAmount: cartFromAPI.cart.pricing.final_price                     
                            })
                                .then((response) => {
                                    if (response.error) {
                                        setLoading(true);
                                        setPaymentIntentError(true);
                                        setErrorText(response.error);
                                        setTimeout(() => {
                                            navigate('/cart')
                                        }, 3000);
                                    }
                                })
                                .catch((error) => console.log('unable to update payment Intent: ', error));
                        }
                    })
                    .catch((error) => console.log('unable to get payment intent: ', error))
            } else if (storagePaymentIntentID === null) {
                //if doesn't exist, create intent
                postPaymentIntent({
                    accessToken,
                    cart: {
                        new_purchases: licensesJSON,
                        upgrades: upgradesJSON,
                    },
                    customer_id: customerID,
                    initialAmount: cartFromAPI.cart.pricing.final_price,
                    purchaseApiUrl
                })
                    .then((response) => {
                        if (response.errors) {
                            setLoading(true);
                            setPaymentIntentError(true);
                            setErrorText('There was an problem on the backend, please wait 5 seconds and try again')
                        } else {
                            setPaymentIntentID(response.id)
                            setPaymentIntentCS(response.cs)
                        }
                    })
                    .catch((error) => console.log('unable to create payment intent: ', error))
            }
        }
        setLoading(false);
    }, [
        customerID,
        licensesInCart,
        upgradesInCart,
    ]);

    React.useEffect(() => {
        if ( 
            success['subscriptions'] === true && 
            success['licenses'] === true && 
            success['upgrades'] === true
        ) {
            setShowSuccessPage(true);
        }
    }, [success]);

    if (loading) {
        return <List/>
    }

    if (setupIntentError || paymentIntentError) {
        return <div style={{color: 'white'}}>{errorText}</div>
    }

    if ((licensesInCartAmount > 0 || upgradesInCartAmount > 0 ) && (!paymentIntentID || !paymentIntentCS)) {
        return <List/>
    }

    if ((subscriptionsInCartAmount > 0) && (!setupIntentID || !setupIntentCS)) {
        return <List/>
    }

    if (showSuccessPage) {
        return <Success successCart={successCart}/>
    }

    return (
        <div>
            {!customerInformation && (
                <BillingDetails 
                    customerID={customerID}
                    paymentIntentID={paymentIntentID}
                    paymentIntentCS={paymentIntentCS}
                    setTaxRate={setTaxRate}
                    setCustomerInformation={setCustomerInformation}
                />
            )}
            {customerInformation && (
                <CardInformation
                    customerInformation={customerInformation}
                    customerID={customerID}
                    paymentIntentID={paymentIntentID}
                    paymentIntentCS={paymentIntentCS}
                    jurisdiction={taxRateIfApplicable.jurisdiction}
                    setupIntentID={setupIntentID}
                    setupIntentCS={setupIntentCS}
                    taxRate={taxRateIfApplicable.tax_rate}
                    setSuccess={setSuccess}
                    setSuccessCart={setSuccessCart}
                />
            )}
        </div>
    )
}

export default BillingInformation;
