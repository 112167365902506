import { GET_SETUP_INTENT_EP } from '../constants/PurchaseEndpoints';
import { SSKey, sessionStorageService } from '../utils/handleSessionStorage';
import {SetupIntent} from '@stripe/stripe-js'

interface GetSetupIntentProps {
    accessToken: string;
    customer_id: string;
    setup_intent_id: string;
    purchaseApiUrl: string;
}

interface GetSetupIntentResponse {
    setupIntentInfo?: SetupIntent;
    error?: Array<{code: number, message: string}>;
}

const getSetupIntent = async ({
    accessToken,
    customer_id,
    setup_intent_id,
    purchaseApiUrl,
}: GetSetupIntentProps): Promise<GetSetupIntentResponse> => {
    try {
        const setupIntent = await fetch(`
            ${purchaseApiUrl}${GET_SETUP_INTENT_EP({
                customer_id,
                setup_intent_id
            })}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'GET',
                mode: 'cors',
        });

        const response = await setupIntent.json();

        if (response) {
            if (response.errors) {
                return {error: response.errors}
            }
            sessionStorageService.set(SSKey.SetupIntentID, response.id);
            sessionStorageService.set(SSKey.SetupIntentClientSecret, response.client_secret);
            return {setupIntentInfo: response}
        }
    } catch(error) {
        console.log('getSetupIntent error:', error)
    }
}

export default getSetupIntent;
